export const URL_BASE = process.env.REACT_APP_URL_API;
export const WSAUTH = "Auth";
export const WSREGISTRO = "Registrar";
export const WSREGISTROB2B = "​Registrar​/registrarUsrB2B";
export const WSREGISTROJUNIOR = "​Registrar/registroUserJunior";
export const WSVALIDARNIT = "Registrar/validacion/";
export const WSDEPARTAMENTOS = "Departamento";
export const WSMUNICIPIO = "Municipio/";
export const WSBARRIO = "Barrio/";
export const WSNOCOBERTURA = 'NoCobertura';
export const WSRECUPERAR = 'Recuperar';
export const WSRECUPERARCODIGO = 'Recuperar/enviarcodigo';
export const WSRECUPERARDATOS = 'Recuperar/enviardatos';
export const WSPRODUCTOS = 'Producto';
export const WSCATALOGO = 'Producto/validarCatalogo';
export const WSVALIDARINVENTARIO = 'Producto/validarInventarioProducto';
export const WSVALIDARINVENTARIOCARRITO = 'Producto/validarInventario';
export const WSCATEGORIAS = 'Categoria';
export const WSCATEGORIASCLIENTE = 'Categoria/Cliente';
export const WSCATEGORIASREGISTRO = 'Categoria/Registro';
export const WSVERIFICACION = 'Verificar';
export const WSPEDIDO = 'Pedido';
export const WSCUPONPORCENTAJE = 'Pedido/Cupon';
export const WSPOLITICA = 'Politica/';
export const WSREENVIAR = 'verificar/reenviar';
export const WSACTUALIZARDATOS = 'Cliente/';
export const WSCLIENTE_ERP = 'Cliente/newClientErp';
export const WSPEDIDOSCLIENTE = 'Cliente/pedido';
export const WSUSUARIOSJRCLIENTE = 'Cliente/usuarios';
export const WSCUPOCLIENTE = 'Cliente/cupo/';
export const WSDETALLEPEDIDO = 'Cliente/pedido/detalle/';
export const WSCANCELARPEDIDO = 'Cliente/pedido/cancelar';
export const WSVALIDAREDICION = 'Cliente/validar/direccion/';
export const WSACTUALIZARDIRECCION = 'Cliente/direccion/';
export const WSACTUALIZARDIRECCIONCLIENTE = 'Cliente/actualizarDireccion/';
export const WSACTUALIZARMAYOR = 'Cliente/mayoredad/';
export const WSACTUALIZARCATORCE = 'Cliente/mayorcatorce/';
export const WSSUCURSALES = "Cliente/sucursales/";
export const WSSUCURSALESJR = "Cliente/sucursalesjr";
export const WSACTULIZARJR = "Cliente/actualizarjr";
export const WSFECHAENTREGA = 'FechaEntrega/diasEntrega/';
export const WSTIPOENTREGA = 'FechaEntrega/tipoEntrega';
export const WSTOPEPEDIDO = 'TopePedido';
export const WSSECCION = 'Seccion';
export const WSBANNER = 'Banner/Banner';
export const WSPOPUP = 'Banner/PopUp';
export const WSPRODUCTOHOME = 'Producto/Home';
export const WSDESCUENTO = 'Producto/validarDescuento'
export const WSVALIDAR = "Producto/validarInventario"
export const WSSECCIONPRODUCTO = 'Seccion/Producto';
export const WSOBSEQUIOSPRODUCTOS = 'ProgObsequio';
export const WSOBSEQUIOSDETALLE = 'ProgObsequio/DetalleObsequio';
export const WSOBSEQUIOSLISTA = 'ProgObsequio/ListaObsequios';
export const WSCARTERA = 'Cartera';
export const WSCARTERAPAGO = '​Cartera​/pago';
export const WSVALIDARESTADO = 'Pagos/validarEstado';
export const WSSUCURSALESCERTIFICADO = 'CertificadoComercial/sucursales';
export const WSCERTIFICADO = 'CertificadoComercial';
export const WSCERTIFICADOCODIGO = 'CertificadoComercial/CodigoCertificado';
export const GETDESCUENTOSFLETE = 'Cliente/descuentoEnvio'
export const URL_TEAM = process.env.REACT_APP_URL_TEAM;
export const WSCOSTOENVIO = 'api/v1/users/auth';
export const URL_TEAM_VALOR_ENVIO = process.env.REACT_APP_URL_COSTO_ENVIO;


export const style = {
    'padding':'6px',
    'width':'100%', 
    'height':'0px',
    'text-align':'center',
    'border-top':'0px',
    'border-bottom': '#757575 solid 1px',
}