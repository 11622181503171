import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// COMPONENTS
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Marca from '../navbar/Marca'
import Categoria from '../navbar/Categoria'
import Footer from '../footer/Footer'
import CardProducto from '../util/CardProducto'
import AlertModal from '../modal/AlertModal'
import PublicidadModal from '../modal/PublicidadModal'
import Auth from '../../helpers/Auth'
import Search from '../util/Search'

import * as Constants from '../../constants/global';
import axios from 'axios';
import ActualizarUsuario from '../../store/usuario/action';
// IMAGEN
import noimage from '../../assets/img/logo_login.png'
import rigth from '../../assets/icon/rigth.svg'

import { Cookies } from 'react-cookie'
import Progress from '../util/Progress';
import ObsequioModal from '../modal/ObsequioModal';
import firebase from 'firebase';
import CoberturaModal from '../modal/CoberturaModal';
const cookies = new Cookies();

const Inicio = (props) => {

    const [search, setSearch] = useState('');
    const [alerta, setAlerta] = useState();
    const [obsequio, setObsequio] = useState();
    const [categorias, setCategorias] = useState();
    const [popup, setPopup] = useState();
    const [banner, setBanner] = useState();
    const [productos, setProductos] = useState();
    const [loader, setLoader] = useState(true);
    const [modalCobertura, setModalCobertura] = useState(false);
    const [sucursal, setSucursal] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [ubicacion, setUbicacion] = React.useState('');

    const options = {
        margin: 10,
        responsiveClass: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 3,
            },
            400: {
                items: 4,
            },
            600: {
                items: 6,
            },
            700: {
                items: 7,
            },
            1000: {
                items: 11,

            }
        },
    };

    const categoriaData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSCATEGORIASCLIENTE + "?codLista=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        axios.get(url).then(res => {
            let listData = res.data || [];
            setCategorias(listData);
            Auth.setCategorias(listData);
        });
    };


    const bannerData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSBANNER;
        if (Object.keys(sucursal).length > 0) {
            url += "?obsequiPrimerPed=" + sucursal.obsequiPrimerPed + "&sucursal=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc();
        }
        axios.get(url).then(res => {
            setBanner(res.data);
        });
    };

    const popupData = (sucursal) => {
        const popCookie = cookies.get("publicidad");
        if (!popCookie &&  Auth.getDoc() !=='1111111111' ) {
            let url = Constants.URL_BASE + Constants.WSPOPUP
            if (Object.keys(sucursal).length > 0) {
                url += "?sucursal=" + sucursal.codigoSac;
            }
            axios.get(url)
                .then(res => {
                    setPopup(res.data);
                });
        }

    };

    const cargarSucursal = async () => {
        var sucursal = Auth.getSucursal();
        if (Object.keys(sucursal).length > 0) {
            obsequios(sucursal);
        } else {
            let url = "";
            if (Auth.getDoc() === null) {
                url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=1111111111&tipoUsuario=M";

            }
            else {
                url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();
            }
            const resp = await axios.get(url)
            let data = resp.data || [];
            setSucursales(data);
            if (data.length > 1) {
                setModalCobertura(true);
            }

            Auth.setSucursal({
                ciudad: data[0].ciudad,
                codListaP: data[0].codListaP,
                codigoSac: data[0].codigoSac,
                direccion: data[0].direccion,
                nombre: data[0].nombre,
                razonSocial: data[0].razonSocial,
                nit: data[0].nit,
                condicionPago: data[0].condicionPago,
                cupo: data[0].cupo,
                codDiasEntrega: data[0].codDiasEntrega,
                pedidoMinimo: data[0].pedidoMinimo,
                pedidoMaximo: data[0].pedidoMaximo,
                bloqueado: data[0].bloqueado,
                agencia: data[0].agencia,
                grupoCliente: data[0].grupoCliente,
                obsequiPrimerPed: data[0].obsequiPrimerPed,
                diasPedido: data[0].diasPedido,
                bloqueadoCartera: 'N'
            });

            setSucursal(data[0]);
            setModalCobertura(false);
            window.location.reload(true);


        }

    };

    const obsequios = (sucursal) => {

        //let url = Constants.URL_BASE + Constants.WSOBSEQUIOSPRODUCTOS + "?clienteERP=" + sucursal.codigoSac + "&CodigoAgencia=" + sucursal.agencia + "&CodigoGrupoCliente=" + sucursal.grupoCliente + "&CodListaPrecio=" + sucursal.codListaP + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        //axios.get(url).then(res => {
        popupData(sucursal);
        productoHomeData(sucursal);
        categoriaData(sucursal);
        bannerData(sucursal);
        //});
    };

    const productoHomeData = (sucursal) => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOHOME + "?codListaP=" + sucursal.codListaP + "&CodigoCliente=" + sucursal.codigoSac + "&usuarioL=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

        axios.get(url).then(res => {
            setLoader(false)
            setProductos(res.data);
        });
    };

    const onClickItem = (item) => {
        if (banner[item].tipoRegalo == 0) {
            if (banner[item].enlace != null) {
                window.location.href = banner[item].enlace;
            }
        } else {
            detalleObsequio(banner[item].id);
        }
    }

    const detalleObsequio = (id) => {
        var sucursal = Auth.getSucursal();
        let url = Constants.URL_BASE + Constants.WSOBSEQUIOSDETALLE + "?idObsequio=" + id + "&sucursal=" + sucursal.codigoSac + "&nit=" + Auth.getDoc();
        axios.get(url).then(res => {
            let data = res.data || [];
            setObsequio(data[0]);
        });
    }

    const clickCategoria = (item) => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/catalogo/" + res);
    }

    const handleCategoria = item => { }

    const handleSearch = value => {
        props.history.push("/catalogo/buscar?q=" + value);
    }

    const handleAuth = async () => {
        axios.get('https://geolocation-db.com/json/').then((response) => {
            setUbicacion(response.data);
        });
        const url = Constants.URL_BASE + Constants.WSAUTH
        const respLogin = await axios.post(url, {
            'usuario': "1111111111",
            'clave': "Abc12345*"
        })

        if (respLogin.data) {
            firebase.analytics().logEvent("login", { usuario: respLogin.data.cliente.usuario });
            ActualizarUsuario(respLogin.data.cliente);
            Auth.setToken(respLogin.data.token);
            Auth.setUbicacion(ubicacion);
            Auth.setDoc(respLogin.data.cliente.usuario);
            Auth.setTipoUsuario(respLogin.data.cliente.tipoUsuario);

            let url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=" + Auth.getDoc() + "&tipoUsuario=" + Auth.getTipoUsuario();

            const resp = await axios.get(url)
            let data = resp.data || [];
            Auth.setSucursal({
                ciudad: data[0].ciudad,
                codListaP: data[0].codListaP,
                codigoSac: data[0].codigoSac,
                direccion: data[0].direccion,
                nombre: data[0].nombre,
                razonSocial: data[0].razonSocial,
                nit: data[0].nit,
                condicionPago: data[0].condicionPago,
                cupo: data[0].cupo,
                codDiasEntrega: data[0].codDiasEntrega,
                pedidoMinimo: data[0].pedidoMinimo,
                pedidoMaximo: data[0].pedidoMaximo,
                bloqueado: data[0].bloqueado,
                agencia: data[0].agencia,
                grupoCliente: data[0].grupoCliente,
                obsequiPrimerPed: data[0].obsequiPrimerPed,
                diasPedido: data[0].diasPedido,
                bloqueadoCartera: 'N'
            });
            props.history.replace('/');
        }
    }

    useEffect(() => {
        if (Auth.getDoc() === null) {
            handleAuth();
            cargarSucursal();
        }
        else if (Auth.getDoc() !== '1111111111') {
            cargarSucursal();
        }
        else {
            handleAuth();
            cargarSucursal();
        }
        //categoriaData();
    }, [])


    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar" >
                    <Navbar item={2} />
                    <Search search={search} updateSearch={handleSearch} />
                    <Sub_nav />
                    {categorias ? <Categoria categoriaId={null} handleCategoria={handleCategoria} /> : null}
                </div>

                <div className="layout">
                    <div className="inicio">
                        {banner && (
                            <div className="banner">
                                <Carousel
                                    showArrows={true}
                                    showThumbs={false}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    onClickItem={onClickItem} >
                                    {banner.map((item, i) => (

                                        <div className="carousel-banner">
                                            {item.tipoRegalo == 0 ?
                                                <img
                                                    key={i}
                                                    className="img-banner"
                                                    src={Constants.URL_BASE + 'banner/' + item.imagen}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                                :
                                                <img
                                                    key={i}
                                                    className="img-banner"
                                                    src={Constants.URL_BASE + 'Regalo/' + item.imagen}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                            }

                                        </div>

                                    ))}

                                </Carousel>
                            </div>
                        )}

                        <div className="home-content">

                            <Marca />
                            <div className="home-seccion">
                                {loader ? <Progress color={"#0194C5"}></Progress> :
                                    <React.Fragment>
                                        {productos && (
                                            <React.Fragment>
                                                {productos.map((item, i) => (                                                    
                                                    <React.Fragment >                                                        
                                                        <div className="inicio-titulo">
                                                            <h2>{item.nombre}</h2>
                                                            <button onClick={() =>{ 
                                                                clickCategoria(item)
                                                                }} >
                                                                Ver todo
                                                                <img src={rigth} alt="icon left" width="10px" height="10px"></img>
                                                            </button>
                                                        </div>
                                                        <div className="pedido-list">
                                                            <div className="product-card">
                                                                {item.producto.map((producto, j) => (
                                                                    <CardProducto
                                                                        key={j}
                                                                        producto={producto}
                                                                        setAlerta={setAlerta} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Footer />
            {popup ? (<PublicidadModal popup={popup} />) : null}
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {obsequio ? (<ObsequioModal detalle={obsequio} updateObsequio={setObsequio} />) : null}
            {modalCobertura ? (<CoberturaModal usuario={props.usuario} sucursales={sucursales} updateSucursal={setSucursal} showModal={setModalCobertura} />) : null}
        </React.Fragment>
    )
}

export default Inicio
