import React , { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';

const Pagina = (props) => {

    const [politica, setPolitica] = useState({});

    const getDetalle = () => {
        let url = Constants.URL_BASE + Constants.WSPOLITICA + props.tipo
        axios.get(url).then((response)=>{
            setPolitica(response.data)
        }).catch((err)=>{
        })
    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    useEffect(() => {
        getDetalle();
    }, [])


    return (
        <React.Fragment>
           <h3>{politica.nombre}</h3>
           <div dangerouslySetInnerHTML={{__html: politica.texto}}></div>
           <div className="panel-button">
                <a className="seguir-comprando" onClick={e => props.history.push('/')}>
                    Seguir comprando
                </a>
           </div>
           
        </React.Fragment>
    )
}


export default withRouter(Pagina);