import React from 'react'

import { Drawer } from 'antd';

import Filter from './Filter'

const DrawerFilter = ({
    color, 
    colorText, 
    lg, 
    filtros, 
    visible,
    setVisible,
    top='215px', 
    handleFiltro}) => {

    const onClose = () => {
        setVisible(false);
    };

    return (
        <Drawer
            placement={'right'}
            closable={true}
            onClose={onClose}
            visible={visible}
            key={'right'}
        >
            <Filter 
                color={color}
                colorText={colorText}
                filtros={filtros}
                handleFiltro={handleFiltro}
            />
        </Drawer>
    )
}

export default DrawerFilter
