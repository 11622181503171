import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Constants from '../../constants/global';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";

import Progress from '../util/Progress';

import Navbar from '../navbar/Navbar';
import Sub_nav from '../navbar/Sub_nav';

import Footer from '../footer/Footer';
import AlertModal from '../modal/AlertModal';

import logo from '../../assets/img/logo_login2.png';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Auth from '../../helpers/Auth';

import {selectActiveUser} from '../../store/usuario/reducer';

const EstadoCuenta = (props) => {

  const [alerta, setAlerta] = useState();
  const [loader, setLoader] = useState(false);
  const [cantidad, setCantidad] = useState(0);
  const [cartera, setCartera] = useState();
  const [total, setTotal] = useState(0);
  const [sucursal, setSucursal] = useState("NONE");

  const getCantidad = () => {
    var cant = 0;
    let cart = JSON.parse(localStorage.getItem('carrito')) || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
    })
    setCantidad(cant);
  }


  useEffect(() => {
    getCantidad();
    getDetalle();
  }, [props.usuario])

  const getDetalle = () => {
    var sucursal = Auth.getSucursal();
    setSucursal(sucursal);
    setLoader(true)
    let url = Constants.URL_BASE + Constants.WSCARTERA + "?clienteERP=" + sucursal.codigoSac + "&pagoCartera=false";
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      setCartera(response.data)
      setTimeout(() => {
        setLoader(false)
        calcularPrecio(response.data);
      }, 2000)
    });
  }

  const calcularPrecio = (lista) => {
    let total = 0;

    lista.map((item) => {
      total += item.saldo;
    })

    setTotal(total);
  }



  const exportPDF = () => {
    console.log(props.usuario);
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    let fecha = new Date().toLocaleString() + '';

    const doc = new jsPDF(orientation, unit, size);
    doc.addImage(logo, "PNG", 500, 5, 55, 48)

    doc.setFontSize(10);
    doc.setFont("calibri", "normal");
    doc.text("Bogota D.C. "+fecha, 20, 60);
    doc.text("Señores", 20, 90);
    doc.text(sucursal.nombre, 20,100);
    doc.text("Código "+sucursal.codigoSac, 20,110);
    doc.text("Tel "+props.usuario.telefono, 20, 120);
    doc.text("E-mail "+props.usuario.email, 20, 130);

    doc.setFont("calibri", "bold");
    doc.text("Asunto			Estado de cuenta", 20, 160);
    
    doc.setFont("calibri", "normal");
    doc.text("Apreciado cliente", 20, 190);

    doc.text("Apreciado cliente de acuerdo con su solicitud, a continuación relacionamos su estado de cuenta:", 20, 220);

    autoTable(doc, { html: '#cartera', startY: 250, margin: 20,useCss:true,theme:'grid',columnStyles:{
      0: {cellWidth: 65,fontSize:10},
      1: {cellWidth: 80,fontSize:10},
      2: {cellWidth: 65,fontSize:10},
      3: {cellWidth: 60,fontSize:10},
      4: {cellWidth: 60,fontSize:10},
      5: {cellWidth: 35,fontSize:10},
      6: {cellWidth: 70,fontSize:10},
      7: {cellWidth: 125,fontSize:10},
    }});

   
    let espacio = calcularEspacio(350,250);
    doc.text("Cualquier novedad le pedimos por favor nos la pueda hacer llegar al correo dkramirez@casaluker.com.co o comunicarse al teléfono  \n3148942609, recuerde que ¡estamos a la orden!", 20, espacio);
    
    doc.text("Departamento de Cartera", 20, espacio+40/*390*/);
    doc.text("CasaLuker S.A.", 20, espacio+70/*420*/);
    doc.text("Para nosotros el futuro sabe bien", 20, espacio+80/*430*/);

    //doc.table(20, 250, generateData(), headers, { autoSize: false,fontSize:10 });
    doc.save("Reporte_" + sucursal.codigoSac + ".pdf")

   
  }

  const calcularEspacio = (espacioMinimo,tabla) => {
    let result = espacioMinimo;

    if(cartera.length > 1 && cartera.length <= 8){
      result = tabla + (cartera.length * 60);
    }else if(cartera.length > 9 ){
      result = tabla + ((cartera.length-12) * 60);
    }

    return result;
  }

  const generateData = () => {
    var result = [];
    let posicion = 0;
    cartera.forEach(item => {
      result.push(Object.assign({}, {
        id : (posicion + 1).toString(),
        Cuenta: item.codigoSac,
        Cliente: sucursal.nombre,
        'Referencia/Factura':item.referencia,
        'Clase de documento': item.descCausaDev,
        'Fecha base documento': item.fechaVencimiento,
        Mora: (item.mora).toString(),
        Valor:(" $" + Intl.NumberFormat().format(item.saldo)),
        Texto: item.concepto
      }))
      posicion++;
    });

    return result;
  }

  const createHeaders = (keys) => {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0
      });
    }
    return result;
  }

  const headers = createHeaders([
    "Cuenta",
    "Cliente",
    "Referencia/Factura",
    "Clase de documento",
    "Fecha base documento",
    "Mora",
    "Valor",
    "Texto"
  ]);
  
  
  return (
    <React.Fragment>

      <div className="navbar navcarrito" >
        <Navbar item={3} />
        <Sub_nav cantidad={cantidad} />
      </div>
      <div className="layout">
        <div className="cartera">
          {cartera ? (
            <React.Fragment>
              {loader ? <Progress color={"#0194C5"}></Progress> :
                (<React.Fragment>
                  <a onClick={() => exportPDF()}>Exportar PDF</a>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="boton"
                    table="cartera"
                    filename={"Reporte_" + sucursal.codigoSac}
                    sheet="tablexls"
                    buttonText="Exportar Excel" />

                  <div className="table-title">
                    <h3>Estado Cartera</h3>
                  </div>
                  <div className="table-responsive">

                    <table className="mytabla mytabla-responsive">
                      <thead>
                        <tr>
                          <th className="text-left">Codigo SAP</th>
                          <th className="text-left">Referencia</th>
                          <th className="text-left">Concepto</th>
                          <th className="text-left">CPag</th>
                          <th className="text-left">CME</th>
                          <th className="text-left">Fecha doc.</th>
                          <th className="text-left">Fecha base</th>
                          <th className="text-left">Venc.neto</th>
                          <th className="text-left">Mora</th>
                          <th className="text-left">Valor</th>
                          <th className="text-left">Texto</th>
                        </tr>
                      </thead>
                      <tbody className="table-hover">
                        {cartera.map((item, i) => (
                          <tr key={i}>
                            <td className="text-left">{item.codigoSac}</td>
                            <td className="text-left">{item.referencia}</td>
                            <td className="text-left">{item.descCausaDev}</td>
                            <td className="text-left">{item.condPago == "DI" ? "Contado" : "Credito"}</td>
                            <td className="text-left">{item.codigoAmmarre}</td>
                            <td className="text-left">{item.fechaDoc.substring(6, 8) + "." + item.fechaDoc.substring(4, 6) + "." + item.fechaDoc.substring(0, 4)}</td>
                            <td className="text-left">{item.fechaVencimiento}</td>
                            <td className="text-left">{item.fechaVecto.substring(6, 8) + "." + item.fechaVecto.substring(4, 6) + "." + item.fechaVecto.substring(0, 4)}</td>
                            <td className="text-left">{item.mora}</td>
                            <td className="text-left">{" $" + Intl.NumberFormat().format(item.saldo)}</td>
                            <td className="text-left">{item.concepto}</td>
                          </tr>
                        ))}

                        <tr>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left"></td>
                          <td className="text-left">{" $" + Intl.NumberFormat().format(total)}</td>
                          <td className="text-left"></td>
                        </tr>
                      </tbody>
                    </table>


                    {/* PDF CARTERA */}
                    <div className="carteraPdf">
                      <table className="mytabla mytabla-responsive" id="cartera">
                        <thead>
                          <tr>
                            <th className="text-left">Codigo SAP</th>
                            <th className="text-left">Cliente</th>
                            <th className="text-left">Referencia</th>
                            <th className="text-left">Concepto</th>
                            <th className="text-left">Fecha base</th>
                            <th className="text-left">Mora</th>
                            <th className="text-left">Valor</th>
                            <th className="text-left">Texto</th>
                          </tr>
                        </thead>
                        <tbody className="table-hover">
                          {cartera.map((item, i) => (
                            <tr key={i}>
                              <td className="text-left">{item.codigoSac}</td>
                              <td className="text-left">{sucursal.nombre}</td>
                              <td className="text-left">{item.referencia}</td>
                              <td className="text-left">{item.descCausaDev}</td>
                              <td className="text-left">{item.fechaVencimiento}</td>
                              <td className="text-left">{item.mora}</td>
                              <td className="text-left">{" $" + Intl.NumberFormat().format(item.saldo)}</td>
                              <td className="text-left">{item.concepto}</td>
                            </tr>
                          ))}

                          <tr>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left"></td>
                            <td className="text-left">{" $" + Intl.NumberFormat().format(total)}</td>
                            <td className="text-left"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
            </React.Fragment>
          ) : null}
        </div>
      </div>
      {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
      <Footer />
    </React.Fragment>
  )
}
const mapStateToprops = (state) => {
  return {
    usuario: selectActiveUser(state)
  }
}

export default withRouter(connect(mapStateToprops) (EstadoCuenta));

