import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { Carousel } from 'react-responsive-carousel'
import { connect } from "react-redux"
import firebase from '../../constants/firebase'
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer'
import CardProducto from '../util/CardProducto'
import AlertModal from '../modal/AlertModal'
import axios from 'axios'
import Auth from '../../helpers/Auth'
import Search from '../util/Search'
import * as Constants from '../../constants/global'

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';


import minus from '../../assets/icon/minus.svg'
import plus from '../../assets/icon/plus.svg'

const Prueba = (props) => {


    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.match.params.numdoc) {
            var url = props.match.params.numdoc;
            var productArray = url.split("-");
        }
        console.log(url);
    }, [props.match.params.numdoc])

    const handlePago = () => {
       
        var checkout = new window.WidgetCheckout({
            currency: 'COP',
            amountInCents: (100000 * 100).toFixed(0),
            reference: "NUMDOC2321",
            publicKey: process.env.REACT_APP_B2B,
            redirectUrl: process.env.REACT_APP_URL_B2B
        });

        checkout.open(function (result) {
            var transaction = result.transaction
            window.location.reload(true);
            console.log('Transaction ID: ', transaction.id)
            console.log('Transaction object: ', transaction)
        })
    }

    return (
        <React.Fragment>
            <div className="content-carrito">
                <button type="button" className="btn-large" onClick={e => handlePago()}>Realizar pago</button>
            </div>
        </React.Fragment>
    )
}


export default Prueba