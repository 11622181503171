import React , { useState, useEffect, useRef } from 'react';
import * as Constants from '../../constants/global';
import axios from 'axios';

import Autocomplete from "./Autocomplete";

import imarker from '../../assets/icon/marker.svg';

let map;
let marker;
let autoComplete;
var departamento = {};
var municipio = {};

function validate(place, depto, muni){
    var selDepto = depto.name;//JSON.parse(depto).name;
    var selCiudad  = muni.name;//JSON.parse(muni).name;
    for (var i = 0; i < place.length; i++) {
        var addressType = place[i].types[0];

        if(addressType === 'locality'){
            var ciudad = place[i].short_name;
            ciudad = ciudad.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            ciudad = ciudad.toUpperCase();

            selCiudad = selCiudad.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            selCiudad = selCiudad.toUpperCase();

            if(ciudad.includes(selCiudad) === false){
                return false;
            }
            
        }else if(addressType === 'administrative_area_level_1'){
            var departamento = place[i].short_name;
            departamento = departamento.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            departamento = departamento.toUpperCase();
            
            if(departamento === "BOGOTA"){
                departamento = "CUNDINAMARCA";
            }

            selDepto = selDepto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            selDepto = selDepto.toUpperCase();

            if(departamento.includes(selDepto) === false){
                return false;
            }
        }   
    }

    return true;
   
}

// PINTA EL MAPA Y EL MARKER
async function loadMap(mapRef, lat, lng, updateLatitud, updateLongitud, updateError, depto, muni){
    var myLatlng = new window.google.maps.LatLng(lat,lng);
    map = new window.google.maps.Map(mapRef.current, {
        center: {lat: lat, lng: lng},
        zoom: 17,
        disableDefaultUI: true
    });

    var styleArray = [
        {
          featureType: "all",
          stylers: [
            { visibility: "off" }
          ]
        },
        {
          featureType: "road",
          stylers: [
            { visibility: "on" }
          ]
        }
    ];
      
    map.setOptions({styles: styleArray});

    var icon = {
        url: imarker,
        scaledSize: new window.google.maps.Size(40, 40),
        origin: new window.google.maps.Point(0,0),
        anchor: new window.google.maps.Point(0, 0)
    };

    marker = new window.google.maps.Marker({
        position: myLatlng,
        draggable: true,
        title: 'Mi direccion',
        icon: icon
    });

    window.google.maps.event.addListener(marker, 'dragend', () => {
        handleMarkerChange(marker.getPosition(), updateLatitud, updateLongitud, updateError, depto, muni)
    });   
    marker.setMap(map);
}

async function handleMarkerChange(pos, updateLatitud, updateLongitud, updateError, depto, muni) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
        latLng: pos
    }, function(responses) {
        if (responses && responses.length > 0) {

            if(validate(responses[0].address_components, depto, muni)){
                updateError('');
                var lat = responses[0].geometry.location.lat();
                var lng = responses[0].geometry.location.lng();
                updateLatitud(lat);
                updateLongitud(lng);
            }else{
                updateError("La dirección no existe en el municipio seleccionado");
            }
        } else {
            updateError("La dirección no existe en el municipio seleccionado");
        }
    });
}



const DireccionPlaces = (props) => {

    const [error, setError] = useState("");
    const [dir1, setDir1] = useState("Calle");
    const [dir2, setDir2] = useState("");
    const [dir3, setDir3] = useState("");
    const [dir4, setDir4] = useState("");
    const [mostrar, setMostrar] = useState(false);
    const mapRef = useRef(null);

    const arrayTipo = ["Calle", "Carrera", "Avenida", "Avenida(carrera)", "Avenida(calle)",
        "Circular", "Circunvalar", "Diagonal", "Manzana", "Transversal", "Via"];

    const handleCobertura = () => {
        
        if(dir1.length === 0 || dir2.length === 0 || dir3.length === 0 || dir4.length === 0){
            setError("Ingrese todos los campos.");
        }else{
            let depto = JSON.parse(props.departamento);
            let muni = JSON.parse(props.municipio);
            let direccion = dir1 + " " + dir2 + " " + dir3 + " " + dir4 + 
                            " " + muni.name.toLocaleLowerCase() + " Colombia";
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({
                address: direccion
            }, function(responses, status) {
                if (status === 'OK') {
                    setError('');
                    if(validate(responses[0].address_components, depto, muni)){
                        var latitude = responses[0].geometry.location.lat();
                        var longitude = responses[0].geometry.location.lng();
                        
                        props.updateLatitud(latitude);
                        props.updateLongitud(longitude);
                        props.updateDireccion(dir1 + " " + dir2 + " # " + dir3 + " - " + dir4);
                        setMostrar(true); 
                        loadMap(mapRef, latitude, longitude, props.updateLatitud,  
                            props.updateLongitud, setError, depto, muni);
                    }else{
                        setError("La dirección no existe en el municipio seleccionado");
                    }
                   
                    
                } else {
                    setError("La dirección no existe en el municipio seleccionado");
                }
            });
        }
    }

    // const getBarrios = (municpioId) =>{
    //     let url = Constants.URL_BASE + Constants.WSBARRIO +  municpioId;
    //     axios.get(url).then(res=>{
    //         let data = res.data || [];
    //         setBarrio(data);
    //     })
    // }

    useEffect(() => {    

        if(props.direccion){
            const tipo =  arrayTipo.find(element => props.direccion.includes(element));//props.direccion.split(",")[0].trim();//
            setDir1(tipo.trim());
            var dirParse = props.direccion.replace(tipo, "");
            var arrParse = dirParse.split("#");
            setDir2(arrParse[0].trim());
            var compleParse = arrParse[1].split("-");
            setDir3(compleParse[0].trim());
            setDir4(compleParse[1].trim());
        }

        if(props.latitud && props.longitud){
            setMostrar(true); 
            let depto = JSON.parse(props.departamento);
            let muni = JSON.parse(props.municipio);
            setTimeout(function(){
                loadMap(mapRef, props.latitud, props.longitud, props.updateLatitud,  
                    props.updateLongitud, setError, depto, muni);
            }, 300);
        }
    }, []);

    useEffect(() => {
        if(props.direccion === ""){
            setDir1("Calle");
            setDir2("");
            setDir3("");
            setDir4("");
        }
    },[props.direccion])

    return (
        <React.Fragment>
            <div className="form-control">
                <label>Dirección <span>*</span>:</label>
                <div className="input-direcion">
                    <select defaultValue={dir1} onChange={e => setDir1(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                        {arrayTipo.map((item,i)=>(
                            <option key={i} value={item} selected={dir1 === item}>{item}</option> 
                        ))}
                    </select>
                    <div className="direccion-group">
                        <input type="text" placeholder="--" value={dir2}  onChange={e => setDir2(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                        <label>#</label>
                        <input type="text" placeholder="--" value={dir3} onChange={e => setDir3(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                        <label>-</label>
                        <input type="text" placeholder="--" value={dir4} onChange={e => setDir4(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                    </div>

                    
                    { error.length > 0 ? (
                        <span className="error">
                            <b>{error}</b>
                        </span>
                    ) : null }
                </div>
            </div>

            <div className="form-row">
                <div className="form-control">
                    <label>Barrio <span>*</span>:</label>
                    { props.barrios ? (
                        <Autocomplete
                            suggestions={props.barrios}
                            error={props.error.Barrio}
                            updateBarrio={props.updateBarrio}
                            barrio={props.barrio}
                        />
                    ) : (
                        <input 
                            className={props.error.Barrio ? "input-error" : null}
                            type="text" 
                            placeholder="Ingrese el barrio" 
                            value={props.barrio} onChange={e => props.updateBarrio(e.target.value) } required></input> 
                        )}
                    { props.error.Barrio ? (
                        <span className="error">
                            <b>{props.error.Barrio}</b>
                        </span>
                    ) : null }
                </div>
                <div className="form-control">
                    <label>Piso / Oficina / Apto:</label>
                    <input 
                    className={props.error.Complemento ? "input-error" : null}
                    type="text" 
                    placeholder="Ingrese complemento" 
                    value={props.complemento} onChange={e => props.updateComplemento(e.target.value.replace("-", "")) } required></input>
                </div>
            </div>
            <button type="button" className="btn-cobertura" onClick={() => handleCobertura()}>Validar cobertura</button>            
            { mostrar ? (
                <React.Fragment>
                <p className="title-map">Ayúdanos a verificar que el pin este ubicado correctamente</p>
                <div 
                    className="map"
                     ref={mapRef}
                     style={{ width: '100%', height: '200px'}}>
                 </div>
                 </React.Fragment>
            ) : (null)}
           
        </React.Fragment>
    )
}


export default DireccionPlaces;