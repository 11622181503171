import React, { useEffect, useRef, useState }from 'react';


const InputOTP = (props) => {

    const [validate, setValidate] = useState();

    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");
    const [otp5, setOtp5] = useState("");
    const [otp6, setOtp6] = useState("");

    const otp1Ref = useRef(null);
    const otp2Ref = useRef(null);
    const otp3Ref = useRef(null);
    const otp4Ref = useRef(null);
    const otp5Ref = useRef(null);
    const otp6Ref = useRef(null);

    const handleKeyUp = (e, index) => {
        setValidate("");
        if((e.keyCode >= 48 && e.keyCode <= 57) || 
                (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
            if(index === 1){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }else{
                    otp2Ref.current.focus();
                }
            }
                
            if(index === 2){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }else{
                    otp3Ref.current.focus();
                }
            }
                
            if(index === 3){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }else{
                    otp4Ref.current.focus();
                }
            }
                
            if(index === 4){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }else{
                    otp5Ref.current.focus();
                }
            }
                
            if(index === 5){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }else{
                    otp6Ref.current.focus();
                }
               
            }
                
            if(index === 6){
                var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
                if(token.length === 6){
                    handelVerify();
                }
            } 

            e.preventDefault(); 
        }
    }

    const handleKeyDown = (e, index) => {
        console.log(e.keyCode);
        if((e.keyCode >= 48 && e.keyCode <= 57) || 
            (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {

            if(index === 1 && otp1)
                setOtp1("");
            if(index === 2 && otp2)
                setOtp2("");
            if(index === 3 && otp3)
                setOtp3("");
            if(index === 4 && otp4)
                setOtp4("");
            if(index === 5 && otp5)
                setOtp5("");
            if(index === 6 && otp6)
                setOtp6("");
        }else{
            e.preventDefault();  
        }
    }

    const handelVerify = () => {
        var token = ""+otp1+otp2+otp3+otp4+otp5+otp6;
        if(token.length < 6){
            setValidate("Ingrese el codigo");
        }else if(token.length === 6){
            props.updateToken(token);
        }
    }

    useEffect(() => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        setOtp5("");
        setOtp6("");
        otp1Ref.current.focus();
    }, [props.error]);

    return (
        <React.Fragment>
            <div className="input-otp">
                <input type='number' 
                    id="otp1" 
                    name="otp1" 
                    ref={otp1Ref} 
                    value={otp1} 
                    onChange={e => setOtp1(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 1)} 
                    onKeyUp={e => handleKeyUp(e, 1)} 
                    pattern="^[0-9]+" />
                <input type='number' 
                    id="otp2" 
                    name="otp2" 
                    ref={otp2Ref} 
                    value={otp2} 
                    onChange={e => setOtp2(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 2)} 
                    onKeyUp={e => handleKeyUp(e, 2)} 
                    pattern="^[0-9]+" />
                <input type='number' 
                    id="otp3" 
                    name="otp3" 
                    ref={otp3Ref} 
                    value={otp3} 
                    onChange={e => setOtp3(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 3)} 
                    onKeyUp={e => handleKeyUp(e, 3)} 
                    pattern="^[0-9]+" />
                <input type='number' 
                    id="otp4" 
                    name="otp4" 
                    ref={otp4Ref} 
                    value={otp4} 
                    onChange={e => setOtp4(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 4)} 
                    onKeyUp={e => handleKeyUp(e, 4)} 
                    pattern="^[0-9]+" />
                <input type='number' 
                    id="otp5" 
                    name="otp5" 
                    ref={otp5Ref} 
                    value={otp5} 
                    onChange={e => setOtp5(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 5)} 
                    onKeyUp={e => handleKeyUp(e, 5)} 
                    pattern="^[0-9]+" />
                <input type='number' 
                    id="otp6" 
                    name="otp6" 
                    ref={otp6Ref} 
                    value={otp6} 
                    onChange={e => setOtp6(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 6)} 
                    onKeyUp={e => handleKeyUp(e, 6)} 
                    pattern="^[0-9]+" />
            </div>
            { validate ? (
                <span className="validate">
                    <b>{validate}</b>
                </span>
            ) : null }
        </React.Fragment>
    )
}


export default InputOTP;