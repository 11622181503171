import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import nav from '../../assets/icon/nav.svg';
import logo  from '../../assets/img/logo.png';

const Navbar = (props) => {

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    const handleMenu= () => {
        document.querySelector(".menu").classList.toggle("show-menu");  
    }

    return (
        <div className="nav-header" id="nav-hidden">
            <div className="nav-content">
                <div className="subnav">
                    <div className="icomenu" onClick={() => handleMenu()}>
                        <img src={nav} alt="icon menu" width="15px" height="15px"></img>
                    </div>
                    <div className="nav-logo">
                        <img
                            onClick={() => handleRedirect('/')} 
                            src={logo}
                            onError={(e)=>{e.target.onerror = null; e.target.src=logo}}>
                        </img>
                    </div> 
                </div>
            </div>
        </div>
    )
}


export default withRouter(Navbar);