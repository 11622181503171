import React, { useState, useEffect } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

import Progress from '../util/Progress';
import Maqueta from '../maqueta/Maqueta';
import DatosCliente from './DatosCliente';
import { RegistroNuevo } from './RegistroNuevo';
import  {Redirect }   from 'react-router-dom';


const Registro = ({ history }) => {

    const [loader, setLoader] = useState(false);
    const [Registro, setRegistro] = useState(true);
    const [nit, setNit] = React.useState('');
    const [errors, setErrors] = React.useState("");
    const [errorg, setErrorg] = React.useState("");
    const [option, setoption] = useState(0)

    const handleVerificar = () => {
        var err = {};

        if (!nit) {
            err['nit'] = "La cédula o documento es requerido."
            setErrors(err);
            return;
        }

        if (Object.keys(err).length == 0) {
            setLoader(true);
            let url = Constants.URL_BASE + Constants.WSVALIDARNIT + nit
            axios.get(url).then(res => {
                console.log(res.data.error);
                setLoader(false);
                if (res.data.nit !== "-1") {
                    setRegistro(false);
                    setErrorg("");
                } else {
                    setErrorg("La cédula o documento no existe comuniquese con Alianza Team");
                }
            }).catch(err => {
                setLoader(false);
                console.log(err.response.data.error);
                setErrorg(err.response.data.error);
            });
        }
    }


    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">

                    {option == 0 && <div>
                        <h3 className="large"> Selecione una opcion para continuar</h3>
                        <button type="button" className="btn-large" style={{ marginTop: "10px" }} onClick={e => setoption(1)}>Colaborador Alianza Team</button>

                        <button type="button" className="btn-large " style={{ marginTop: "10px" }} onClick={e => setoption(2)}>Usuario Alianzas</button>

                    </div>}
                    {option == 1? <div className="main">


                        <h3 className="large">Registro </h3>
                        <form>
                            {loader ? <Progress color={"#0194C5"}></Progress> :
                                <React.Fragment>
                                    <div className="form-control">
                                        <label>Cédula o Documento: <span>*</span></label>
                                        <input
                                            className={errors.nit ? "input-error" : null}
                                            type="number"
                                            placeholder="Cédula o Documento"
                                            name="nit"
                                            id="nit"
                                            disabled={!Registro ? "disable" : ""}
                                            onChange={e => setNit(e.target.value)}
                                            value={nit}
                                        />
                                        {errors.nit ?
                                            (
                                                <span className="error">
                                                    <b>{errors.nit}</b>
                                                </span>
                                            ) : null
                                        }

                                        {errorg.length > 0 ? (
                                            <span className="error">
                                                <p>{errorg}</p>
                                            </span>
                                        ) : null}

                                        {!Registro ?
                                            (
                                                <React.Fragment>
                                                    <DatosCliente
                                                        nit={nit}
                                                    />
                                                </React.Fragment>
                                            )
                                            : null}

                                        {!Registro ? null
                                            :
                                            (
                                                <React.Fragment>
                                                    <button type="button" className="btn-large " onClick={() => handleVerificar()}>Verificar</button>
                                                </React.Fragment>
                                            )
                                        }

                                        <button type="button" className="btn-large outline" onClick={e => history.push('/login')}>Cancelar</button>
                                    </div>
                                </React.Fragment>
                            }
                        </form>
                    </div>:option==2&&<Redirect to='/RegistroNuevo'/>}

                </div>
            </div>
        </div>
    )
}

export default Registro;