import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import * as Constants from '../../constants/global';
import firebase from '../../constants/firebase';
import axios from 'axios';
import Auth from '../../helpers/Auth';

// COMPONENTES
import SucursalModal from '../modal/SucursalModal';
import AlertModal from '../modal/AlertModal';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import {selectActiveUser} from '../../store/usuario/reducer';

// IMAGENES
import left from '../../assets/icon/left.svg';
import rigth from '../../assets/icon/rigth.svg';

const Categoria = (props) => {
    
    const [alerta, setAlerta] = useState();
    const [categorias, setCategorias] = useState();
    const [active, setActive] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [scrollEnd, setScrollEnd] = useState(true);

    useEffect(() => {

        const categoriaData = () => {
            let listData = Auth.getCategorias();
            props.handleCategoria(listData[0]);
            setCategorias(listData);
        };
        categoriaData();
        setActive(props.categoriaId);
    }, [props.categoriaId])
    


    const scrollAction = (item) => {
        document.getElementById('scroll-categoria').scrollLeft += item;
    }

   

    const clickCategoria = (item) => e => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/catalogo/" + res);
    }

    setTimeout(function(){
        if(document.getElementById('scroll-categoria')){
            if(document.getElementById('scroll-categoria').scrollLeft 
            + document.getElementById('scroll-categoria').offsetWidth + 10
            >= document.getElementById('scroll-categoria').scrollWidth){
                setScrollEnd(false);
            }else{
                setScrollEnd(true);
            }
            
            document.getElementById("scroll-categoria").onscroll = function() {
                
                setScroll(document.getElementById('scroll-categoria').scrollLeft);
                
                if(document.getElementById('scroll-categoria').scrollLeft 
                + document.getElementById('scroll-categoria').offsetWidth + 10
                >= document.getElementById('scroll-categoria').scrollWidth){
                    setScrollEnd(false);
                }else{
                    setScrollEnd(true);
                }
            }
        }
        
    }, 300);

   

    return (
        <React.Fragment>
            { categorias ? (
                <div className="categoria">
                    { scroll > 0 ? (
                        <div className="arrow" 
                            onClick={() => scrollAction(-60)}>
                            <img src={left} alt="icon menu" width="20px" height="20px"></img>
                        </div>
                    ) : null}
                    <ul id="scroll-categoria">
                        {categorias.map((item, i) => (
                            <li key={i}>
                                <a 
                                    className={active == item.id ? 'active': null} 
                                    onClick={clickCategoria(item)}>
                                    {item.nombre}
                                </a>
                            </li>
                        ))}
                    </ul>
                    { scrollEnd ? (
                        <div className="arrow" onClick={() => scrollAction(60)}>
                            <img src={rigth} alt="icon menu" width="20px" height="20px"></img>
                        </div>
                    ) : null }
                </div>
            ) : null }

            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
            
        </React.Fragment> 
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarUsuario}) (Categoria));