import * as Const from '../conts';
const initialState = { carrito: "" };

export default (state = initialState, action) => {
    switch (action.type) {
        case Const.SET_CARRITO:
            return {
                ...state, 
                carrito: action.payload
            };
        default:
            return state;
    }
};

export const selectActiveCarrito = state => state.carritoReducer.carrito;
