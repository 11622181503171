import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { Carousel } from 'react-responsive-carousel'
import { connect } from "react-redux"
import firebase from '../../constants/firebase'
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer'
import CardProducto from '../util/CardProducto'
import AlertModal from '../modal/AlertModal'
import axios from 'axios'
import Auth from '../../helpers/Auth'
import Search from '../util/Search'
import * as Constants from '../../constants/global'

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';

import noimage from '../../assets/img/logo_login.png'
import minus from '../../assets/icon/minus.svg'
import plus from '../../assets/icon/plus.svg'

const Detalle = (props) => {

    const [search, setSearch] = useState('');
    const [alerta, setAlerta] = useState();
    const [producto, setProducto] = useState();
    const [imagenes, setImagenes] = useState();
    const [recomenado, setRecomendado] = useState([]);
    const [sucursal, setSucursal] = useState('');

    const handleSearch = value => {
        props.history.push("/catalogo/packs-15?q=" + value);
    }


    // AUMENTA LA CANTTIDAD
    const handleMas = item => {
        var sucursal = Auth.getSucursal();
        if (Object.keys(sucursal).length > 0) {
            var carrito = props.carrito || {};
            if (carrito[item.codigoSku]) {
                if (carrito[item.codigoSku]['cantidad'] < carrito[item.codigoSku].inventario) {
                    const valor = carrito[item.codigoSku]["cantidad"] + 1;
                    let estado = true;

                    if (carrito[item.codigoSku].maximo != -1) {
                        console.log(carrito[item.codigoSku].maximo < valor);
                        if (carrito[item.codigoSku].maximo < valor) {
                            estado = false;
                            setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[item.codigoSku].maximopPedido + " cada " + sucursal.diasPedido + " dias",
                                btnOk: "Aceptar",
                                fnOk: function () {
                                    if (carrito[item.codigoSku].maximo == 0) {
                                        delete carrito[item.codigoSku];
                                    } else {
                                        carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].maximo);
                                    }
                                    Auth.setCarrito(carrito);
                                    props.ActualizarCarrito(Auth.getCarrito());
                                },
                                fnCancel: null
                            })
                        }
                    }

                    if (estado) carrito[item.codigoSku]["cantidad"] += 1;
                } else {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[item.codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }

            } else {
                carrito[item.codigoSku] = {
                    id: item.id,
                    nombre: item.nombre,
                    precio: parseFloat(item.precio),
                    iva: 0,
                    ico: parseInt(item.ico),
                    cantidad: parseInt(item.minimo != -1 ? item.minimo : 1),
                    descuento: 0,
                    inventario: item.inventario,
                    idCategoria: item.categoriaId,
                    idSeccion: item.seccionId,
                    regalo: 0,
                    maximo: item.maximo,
                    minimo: item.minimo,
                    maximopPedido: item.maximopPedido
                }
            }
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
        } else {
            props.ActualizarCarrito({});
        }
    }

    const clickCategoria = (categoriaId, categoria) => {
        var id = categoriaId;
        var name = categoria;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/catalogo/" + res);
    }

    const handleMenos = item => {
        var carrito = props.carrito || {};
        if (carrito[item.codigoSku]) {
            if (carrito[item.codigoSku]["cantidad"] == 1) {
                delete carrito[item.codigoSku];
            } else {
                const valor = carrito[item.codigoSku]["cantidad"] - 1;
                let estado = true;
                if (carrito[item.codigoSku].minimo != -1) {
                    if (carrito[item.codigoSku].minimo > valor) {
                        estado = false;
                        setAlerta({
                            tipo: "warning",
                            titulo: "Advertencia.",
                            mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[item.codigoSku].minimo,
                            btnOk: "Aceptar",
                            fnOk: function () {
                                delete carrito[item.codigoSku];
                                Auth.setCarrito(carrito);
                                props.ActualizarCarrito(Auth.getCarrito());
                            },
                            fnCancel: null
                        })
                    }
                }
                if (estado) carrito[item.codigoSku]["cantidad"] -= 1;
            }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    }

    const handleChangeCantidad = (item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length > 0) {
            delete carrito[item.codigoSku];
        } else {
            if (carrito[item.codigoSku]) {
                if (valor <= carrito[item.codigoSku].inventario) {
                    carrito[item.codigoSku]["cantidad"] = parseInt(valor);
                } else {
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, inventario disponible " + carrito[item.codigoSku].inventario,
                        btnOk: "Aceptar",
                        fnOk: function () { },
                        fnCancel: null
                    })
                }
            } else {
                carrito[item.codigoSku] = {
                    id: item.id,
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor),
                    iva: 0,
                    ico: parseInt(item.ico),
                    descuento: 0,
                    inventario: item.inventario,
                    idCategoria: item.categoriaId,
                    idSeccion: item.seccionId,
                    regalo: 0,
                    maximo: item.maximo,
                    minimo: item.minimo,
                    maximopPedido: item.maximopPedido
                }

            }
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
        }
    }

    const handleBlur = (item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length == 0) {
            delete carrito[item.codigoSku];
        } else {
            let estado = true;

            if (carrito[item.codigoSku].minimo != -1) {
                if (carrito[item.codigoSku].minimo > valor) {
                    estado = false;
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[item.codigoSku].minimo,
                        btnOk: "Aceptar",
                        fnOk: function () {
                            carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].minimo);
                            Auth.setCarrito(carrito);
                            props.ActualizarCarrito(Auth.getCarrito());
                        },
                        fnCancel: null
                    })
                }
            }
            if (carrito[item.codigoSku].maximo != -1) {
                if (carrito[item.codigoSku].maximo < valor) {
                    estado = false;
                    setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[item.codigoSku].maximopPedido + " cada " + sucursal.diasPedido + " dias",
                        btnOk: "Aceptar",
                        fnOk: function () {
                            if (carrito[item.codigoSku].maximo == 0) {
                                delete carrito[item.codigoSku];
                            } else {
                                carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].maximo);
                            }
                            Auth.setCarrito(carrito);
                            props.ActualizarCarrito(Auth.getCarrito());
                        },
                        fnCancel: null
                    })
                }
            }

            if (estado) carrito[item.codigoSku]["cantidad"] = parseInt(valor);
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    };

    const loadRecomendado = (id, codigoListaP, codigoSac) => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS + "/Recomendado?ProductoId=" + id + "&codListaP=" + codigoListaP + "&CodigoCliente=" + codigoSac + "&usuarioL=" + Auth.getDoc();
        axios.get(url)
            .then(res => {
                setRecomendado(res.data);
            });
    }

    const loadProducto = (id, codigoListaP, codigoSac) => {
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS + "/Detalle?ProductoId=" + id + "&codListaP=" + codigoListaP + "&CodigoCliente=" + codigoSac;
        axios.get(url)
            .then(res => {
                console.log(res.data);
                setProducto(res.data.producto);
                setImagenes(res.data.imagenes);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.match.params.producto) {
            var sucursal = Auth.getSucursal();
            var sucursal = Auth.getSucursal();
            var urlProduct = props.match.params.producto;
            var productArray = urlProduct.split("-");
            var productId = productArray[productArray.length - 1];
            var codigoListaP = sucursal.codListaP ? sucursal.codListaP : "0";
            var codigoSac = sucursal.codigoSac ? sucursal.codigoSac : "0";
            setSucursal(sucursal);
            loadProducto(productId, codigoListaP, codigoSac);
            loadRecomendado(productId, codigoListaP, codigoSac);
        }
    }, [props.match.params.producto])

    return (
        <React.Fragment>
            <div className="content-carrito">
                <div className="navbar principal navcarrito" >
                    <Navbar item={2} />
                    {/* <Search search={search} updateSearch={handleSearch} /> */}
                    <Sub_nav />

                </div>
                <div className="layout">
                    {producto ? (
                        <div className="detalle">
                            <div className="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                                    <li className="breadcrumb-item"><a onClick={() => clickCategoria(producto.categoriaId, producto.categoria)}>{producto.categoria}</a></li>
                                    <li className="breadcrumb-item active">{producto.codigoSku + " - " + producto.nombre}</li>
                                </ol>
                            </div>
                            <div className="detail-product">
                                <div className="detail-content">
                                    <div className="detail-gallery"></div>
                                    <div className="detail-photo">
                                        {/* <img 
                                            style={producto.agotado == 1 ? {opacity: 0.3} : {}}
                                            className="img-product"
                                            src={ Constants.URL_BASE +'/image/'+ producto.codigoSku.trim() + '.jpg'}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                        </img> */}

                                        <Carousel
                                            showArrows={true}
                                            showThumbs={false}
                                            autoPlay={false}
                                            infiniteLoop={true}
                                            showStatus={false}>
                                            <div>
                                                <img
                                                    style={producto.inventario == 0 ? { opacity: 0.3 } : {}}
                                                    className="img-product"
                                                    src={Constants.URL_BASE + '/image/' + producto.codigoSku.trim() + '.jpg'}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                </img>
                                            </div>
                                            {/* {imagenes ? (imagenes.map((item, i) => (
                                                <div>
                                                    <img
                                                        style={producto.inventario == 0 ? { opacity: 0.3 } : {}}
                                                        className="img-product"
                                                        src={Constants.URL_BASE + '/listaImagenes/' + producto.codigoSku.trim() + "/" + item.nombre}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                                                    </img>
                                                </div>
                                            ))) : null} */}
                                        </Carousel>

                                    </div>
                                    <div className="detail-info">
                                        <p className="detail-shop">Alianza Team</p>
                                        <h2 className="detail-title"><b>{producto.nombre}</b></h2>
                                        <p className="detail-description">{producto.categoria}</p>
                                        {producto.unidadXcaja == 0 || producto.unidad.length == 0 ? null : <p className="detail-description">{producto.unidadXcaja + " UND por " + producto.unidad}</p>}
                                        {producto.fechaVigencia == null ? <p style={{ color: 'transparent' }} className="detail-description">{"Desde dd/MM/yyyy Hasta dd/MM/yyyy"}</p> : <p className="detail-description">{producto.fechaVigencia}</p>}
                                        <p className="detail-description"><b>Descripción:</b></p>
                                        <div className="detail-description" dangerouslySetInnerHTML={{ __html: producto.descripcion }}></div>

                                        <p className="detail-price">
                                            {"$ " + new Intl.NumberFormat("en", {
                                                numberingSystem: "latn",
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                                currency: "COP"
                                            }).format((producto.precio + (producto.precio * (producto.iva / 100))) || 0) + " UND"}
                                        </p>
                                        {producto.precioTachado != 0 ? <p className="detalle-precioTachado">
                                            {" $" + new Intl.NumberFormat("en", {
                                                numberingSystem: "latn",
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                                currency: "COP"
                                            }).format(producto.precioTachado + (producto.precioTachado * (producto.iva / 100)) )}
                                        </p>
                                            : null}
                                        {producto.inventario !== 0 ? (
                                            <div className="action">
                                                {props.carrito[producto.codigoSku] ? (
                                                    <div className="controls">
                                                        <button role="button" onClick={() => handleMenos(producto)}>
                                                            <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                                        </button>
                                                        <input type="number" minLength="0" maxLength="99"
                                                            onChange={e => handleChangeCantidad(producto, e.target.value)}
                                                            onBlur={e => handleBlur(producto, e.target.value)}
                                                            value={props.carrito[producto.codigoSku].cantidad} />
                                                        <button role="button" onClick={() => handleMas(producto)}>
                                                            <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <button className="agregar" onClick={() => handleMas(producto)}>Agregar</button>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="agotado">
                                                <p>Agotado</p>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            {recomenado && (
                                <React.Fragment>
                                    <div className="inicio-titulo">
                                        <h2>Recomendados</h2>
                                    </div>
                                    <div className="pedido-list">
                                        <div className="product-card">
                                            {recomenado.map((item, i) => (
                                                <CardProducto
                                                    key={i}
                                                    producto={item}
                                                    setAlerta={setAlerta} />
                                            ))}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                        </div>
                    ) : null}
                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            <Footer />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(Detalle));