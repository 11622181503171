import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from '../../constants/firebase'
import Auth from '../../helpers/Auth'
import * as Constants from '../../constants/global';
import AlertModal from '../modal/AlertModal'
import axios from 'axios'
import { BsFillHexagonFill } from 'react-icons/bs';

import minus from '../../assets/icon/minus.svg'
import plus from '../../assets/icon/plus.svg'
import noimage from '../../assets/img/logo_login2.png'

//REDUX
import ActualizarCarrito from '../../store/carrito/action';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import { selectActiveUser } from '../../store/usuario/reducer';

const CardProducto = (props) => {

    const [alerta, setAlerta] = useState();
    const [diasPedido, setDiasPedido] = useState('');

    const validate = (id, callBack) => {
        callBack(true)
    }

    const handelItem = (item) => {
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        props.history.push("/producto/" + res);
    }


    // AUMENTA LA CANTTIDAD
    const handleMas = item => {
        
        validate(item.categoriaId, function (resp) {          
            if (resp) {
                var sucursal = Auth.getSucursal();          
                if (Object.keys(sucursal).length > 0) {
                    var carrito = props.carrito || {};            
                    if (carrito[item.codigoSku]) {                       
                        if (carrito[item.codigoSku]['cantidad'] < carrito[item.codigoSku].inventario) {
                            const valor = carrito[item.codigoSku]["cantidad"] + 1;
                            let estado = true;
                            if (carrito[item.codigoSku].maximo != -1) {
                                if (carrito[item.codigoSku].maximo < valor) {
                                    estado = false;
                                    props.setAlerta({
                                        tipo: "warning",
                                        titulo: "Advertencia.",
                                        mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[item.codigoSku].maximopPedido + " cada " + diasPedido + " dias",
                                        btnOk: "Aceptar",
                                        fnOk: function () {
                                            if (carrito[item.codigoSku].maximo == 0) {
                                                delete carrito[item.codigoSku];
                                            } else {
                                                carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].maximo);
                                            }
                                            Auth.setCarrito(carrito);
                                            props.ActualizarCarrito(Auth.getCarrito());
                                        },
                                        fnCancel: null
                                    })
                                }
                            }

                            if (estado) carrito[item.codigoSku]["cantidad"] += 1;
                        } else {
                            props.setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, inventario disponible " + carrito[item.codigoSku].inventario,
                                btnOk: "Aceptar",
                                fnOk: function () { },
                                fnCancel: null
                            })
                        }

                    } else {
                        console.log(item)
                        carrito[item.codigoSku] = {
                            id: item.id,
                            nombre: item.nombre,
                            precio: parseFloat(item.precio),
                            iva: 0,
                            ico: parseInt(item.ico),
                            cantidad: parseInt(item.minimo != -1 ? item.minimo+1 : 1),
                            descuento: 0,
                            inventario: item.inventario,
                            idCategoria: item.categoriaId,
                            idSeccion: item.seccionId,
                            regalo: 0,
                            maximo: item.maximo,
                            minimo: item.minimo,
                            maximopPedido: item.maximopPedido,
                            marca: item.marca
                        }
                    }
                    Auth.setCarrito(carrito);
                    props.ActualizarCarrito(Auth.getCarrito());
                } else {
                    props.ActualizarCarrito({});
                }
            }
        })
    }

    const handleMenos = item => {
        validate(item.categoria, function (resp) {
            if (resp) {
                var carrito = props.carrito || {};
                if (carrito[item.codigoSku]) {
                    if (carrito[item.codigoSku]["cantidad"] == 1) {
                        delete carrito[item.codigoSku];
                    } else {
                        const valor = carrito[item.codigoSku]["cantidad"] - 1;
                        let estado = true;
                        if (carrito[item.codigoSku].minimo != -1) {
                            if (carrito[item.codigoSku].minimo > valor) {
                                estado = false;
                                props.setAlerta({
                                    tipo: "warning",
                                    titulo: "Advertencia.",
                                    mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[item.codigoSku].minimo,
                                    btnOk: "Aceptar",
                                    fnOk: function () {
                                        delete carrito[item.codigoSku];
                                        Auth.setCarrito(carrito);
                                        props.ActualizarCarrito(Auth.getCarrito());
                                    },
                                    fnCancel: null
                                })
                            }
                        }
                        if (estado) carrito[item.codigoSku]["cantidad"] -= 1;
                    }
                }
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            }
        })
    }

    const handleChangeCantidad = (item, valor) => {
        validate(item.categoria, function (resp) {
            if (resp) {
                let carrito = props.carrito || {};
                if (valor <= 0 && Object.keys(valor).length > 0) {
                    delete carrito[item.codigoSku];
                } else {
                    if (carrito[item.codigoSku]) {
                        if (valor <= carrito[item.codigoSku].inventario) {
                            carrito[item.codigoSku]["cantidad"] = parseInt(valor);
                        } else {
                            props.setAlerta({
                                tipo: "warning",
                                titulo: "Advertencia.",
                                mensaje: "Lo sentimos, inventario disponible " + carrito[item.codigoSku].inventario,
                                btnOk: "Aceptar",
                                fnOk: function () { },
                                fnCancel: null
                            })
                        }
                    } else {
                        carrito[item.codigoSku] = {
                            id: item.id,
                            nombre: item.nombre,
                            precio: item.precio,
                            cantidad: parseInt(valor),
                            iva: 0,
                            ico: parseInt(item.ico),
                            descuento: 0,
                            inventario: item.inventario,
                            idCategoria: item.categoriaId,
                            idSeccion: item.seccionId,
                            regalo: 0,
                            maximo: item.maximo,
                            minimo: item.minimo,
                            maximopPedido: item.maximopPedido,
                            marca: item.marca
                        }
                    }
                }
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            }
        })

    }

    const handleBlur = (item, valor) => {
        var carrito = props.carrito || {};
        if (valor <= 0 && Object.keys(valor).length == 0) {
            delete carrito[item.codigoSku];
        } else {
            let estado = true;

            if (carrito[item.codigoSku].minimo != -1) {
                if (carrito[item.codigoSku].minimo > valor) {
                    estado = false;
                    props.setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, la cantidad minima para agregar el producto es de " + carrito[item.codigoSku].minimo,
                        btnOk: "Aceptar",
                        fnOk: function () {
                            carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].minimo);
                            Auth.setCarrito(carrito);
                            props.ActualizarCarrito(Auth.getCarrito());
                        },
                        fnCancel: null
                    })
                }
            }
            if (carrito[item.codigoSku].maximo != -1) {
                if (carrito[item.codigoSku].maximo < valor) {
                    estado = false;
                    props.setAlerta({
                        tipo: "warning",
                        titulo: "Advertencia.",
                        mensaje: "Lo sentimos, la cantidad maxima que se puede agregar al carrito es de " + carrito[item.codigoSku].maximopPedido + " cada " + diasPedido + " dias",
                        btnOk: "Aceptar",
                        fnOk: function () {
                            if (carrito[item.codigoSku].maximo == 0) {
                                delete carrito[item.codigoSku];
                            } else {
                                carrito[item.codigoSku]["cantidad"] = parseInt(carrito[item.codigoSku].maximo);
                            }
                            Auth.setCarrito(carrito);
                            props.ActualizarCarrito(Auth.getCarrito());
                        },
                        fnCancel: null
                    })
                }
            }

            if (estado) carrito[item.codigoSku]["cantidad"] = parseInt(valor);
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
    };

    useEffect(() => {
        var sucursal = Auth.getSucursal();
        setDiasPedido(sucursal.diasPedido);
    }, [])

    return (
        <React.Fragment>
            <div className="card" key={props.key}>
                <div className="card-img" onClick={() => handelItem(props.producto)}>
                {(props.producto.descuentoPorcentaje !== "0" && props.producto.precioTachado !== 0)&&
                        <div className="card-info-vi">
                            <BsFillHexagonFill color="#3a8557" size="3rem" />
                            <div className="centrado">{`${props.producto.descuentoPorcentaje}%`} </div>
                        </div>  
                }
                    <img
                        style={props.producto.inventario == 0 ? { opacity: 0.3 } : {}}
                        className="img-product"
                        src={Constants.URL_BASE + '/image/' + props.producto.codigoSku.trim() + '.jpg'}
                        onError={(e) => { e.target.onerror = null; e.target.src = noimage }}>
                    </img>
                </div>

                <p className="nombre">{props.producto.nombre.trim()}</p>
                <div className='precioDesc'>
                    {props.producto.precioTachado != 0 ? <p className="precioTachado">
                        {" $" + new Intl.NumberFormat("en", {
                            numberingSystem: "latn",
                            style: "decimal",
                            maximumFractionDigits: 0,
                            currency: "COP"
                        }).format(props.producto.precioTachado + (props.producto.precioTachado * (props.producto.iva / 100)) )}
                    </p>
                        : null}
                    <p className="precio">
                        {" $" + new Intl.NumberFormat("en", {
                            numberingSystem: "latn",
                            style: "decimal",
                            maximumFractionDigits: 0,
                            currency: "COP"
                        }).format(props.producto.precio + (props.producto.precio * (props.producto.iva / 100))) + " UND"}
                    </p>
                </div>
                {props.producto.inventario !== 0 ? (
                    <div className="action">
                        {props.carrito[props.producto.codigoSku] ? (
                            <div className="controls" style={{ border: "1px solid " + props.color }}>
                                <button role="button" onClick={() => handleMenos(props.producto)}>
                                    {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                    <svg height="15pt" width="15pt" viewBox="0 -192 469.33333 469" xmlns="http://www.w3.org/2000/svg"><path d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0" fill={props.color ? props.color : "#3a8557"} /></svg>
                                </button>
                                <input type="number" minLength="0" maxLength="99"
                                    onChange={e => handleChangeCantidad(props.producto, e.target.value)}
                                    onBlur={e => handleBlur(props.producto, e.target.value)}
                                    value={props.carrito[props.producto.codigoSku].cantidad} />
                                <button role="button" onClick={() => handleMas(props.producto)}>
                                    {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                    <svg height="15pt" width="15pt" viewBox="0 0 469.33333 469.33333" xmlns="http://www.w3.org/2000/svg"><path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0" fill={props.color ? props.color : "#3a8557"} /></svg>
                                </button>
                            </div>
                        ) : (
                            <button className="agregar" style={{ backgroundColor: props.color }} onClick={() => handleMas(props.producto)}>Agregar</button>
                        )}
                    </div>
                ) : (
                    <div className="agotado">
                        <p>Agotado</p>
                    </div>
                )}

            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>

    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(CardProducto));
