import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import {connect} from "react-redux"
import AlertModal from '../modal/AlertModal'
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer'
import axios from 'axios'
import Auth from '../../helpers/Auth'
import * as Constants from '../../constants/global'

import noimage from '../../assets/img/logo_login.png'
import minus from '../../assets/icon/minus.svg'
import plus from '../../assets/icon/plus.svg'

import ActualizarCarrito from '../../store/carrito/action';
import {selectActiveCarrito} from '../../store/carrito/reducer';
import {selectActiveUser} from '../../store/usuario/reducer';

const Pack = (props) => {

    const [alerta, setAlerta] = useState();
    const [cantidad, setCantidad] = useState(0);
    const [total, setTotal] = useState(0);
    const [productos, setProductos] = useState();
    const [catorce, setCatorce] = useState(false);
    const [mayor, setMayor] = useState(false);

    const loadProducto = (id) => {
        var sucursal = Auth.getSucursal();
        
        let url = Constants.URL_BASE + Constants.WSPRODUCTOS + "/Pack?packId=" + id;
        url += "&mayor=true&catorce=true";
        if(Object.keys(sucursal).length > 0){
            url += "&codListaP=" + sucursal.codListaP;
        }
        axios.get(url)
        .then(res => {   
            console.log(res.data);
            setProductos(res.data);
            getTotal(res.data);
        });
    }

    const getTotal = (listProductos) => {
        var sum = 0;
        if(listProductos){
            listProductos.map((item, i) => {
                if(item.categoriaId == 5)
                    setMayor(true);

                if(item.categoriaId == 6)
                    setCatorce(true);
                
                sum += item.cantidad * item.precio;
            })
        }
        setTotal(sum);
    }

    const handleMas = (item) => {
        enviraCarrito();
        /*if(mayor || catorce){            
            var cliente = props.usuario;
            if(cliente){
                if(!cliente.fechaMayorEdad && mayor){
                    setAlerta({
                        tipo: "warning",
                        titulo: "¿Usted es mayor de edad?",
                        mensaje: "Recuerde que este tipo de productos solo puede ser vendidos a mayores de edad.",
                        btnOk: "Si",
                        btnCancel: "No",
                        fnOk: function(){
                            var cliente = props.usuario;
                            if(cliente){
                                let fa=new Date();
                                let fe=new Date(fa.getFullYear(),fa.getMonth(),fa.toLocaleString('es-CO',{day:'numeric'}),fa.getHours(), fa.getMinutes(),fa.getMilliseconds());
                                axios.put(Constants.URL_BASE + Constants.WSACTUALIZARMAYOR + cliente.id, {
                                    'fechaMayorEdad': fe.toISOString()
                                },{
                                    headers:{
                                        Authorization: `Bearer ${Auth.getToken()}`
                                    }
                                }).then((response)=>{
                                    cliente.fechaMayorEdad = fe.toISOString();
                                    props.ActualizarUsuario(cliente);
                                    document.querySelector(".modal-sucursal").classList.remove("show-modal");
                                    // callBack(true)
                                }).catch((err)=>{
                                })
                            } 
                        },
                        fnCancel: function(){
                            //callBack(false)
                        }
                    })      
                }else if(!cliente.fechaMayor14 && catorce){
                    setAlerta({
                        tipo: "warning",
                        titulo: "¿Usted es mayor de 14 años?",
                        mensaje: "Recuerde que este tipo de productos solo puede ser vendidos a mayores de 14 años.",
                        btnOk: "Si",
                        btnCancel: "No",
                        fnOk: function(){
                            var cliente = props.usuario;
                            if(cliente){
                                let fa=new Date();
                                let fe=new Date(fa.getFullYear(),fa.getMonth(),fa.toLocaleString('es-CO',{day:'numeric'}),fa.getHours(), fa.getMinutes(),fa.getMilliseconds());
                                axios.put(Constants.URL_BASE + Constants.WSACTUALIZARCATORCE + cliente.id, {
                                    'fechaMayorEdad': fe.toISOString()
                                },{
                                    headers:{
                                        Authorization: `Bearer ${Auth.getToken()}`
                                    }
                                }).then((response)=>{
                                    cliente.fechaMayor14 = fe.toISOString();
                                    props.ActualizarUsuario(cliente);
                                    document.querySelector(".modal-sucursal").classList.remove("show-modal");
                                    // callBack(true)
                                }).catch((err)=>{
                                    // callBack(false)
                                })
                            } 
                        },
                        fnCancel: function(){
                            // props.history.push('/');
                            //callBack(false)
                        }
                    })     
                }else{
                    enviraCarrito();
                }
            }else{
                if(!Auth.getToken()){
                    setAlerta({
                        tipo: "info",
                        titulo: "Inicio de sesión.",
                        mensaje: "Debes ser mayor de edad para comprar este producto, por favor inicia sesión o regístrate y acepta los términos y condiciones para continuar.",
                        btnOk: "Ingresar o Registrarse",
                        btnCancel: "Cancelar",
                        fnOk: function(){
                            props.history.push('/login');
                        },
                        fnCancel: function () {
                            // props.history.push('/');
                            // callBack(false)
                        }
                    })   
                }
            }
        }else{
            enviraCarrito();
        }*/
    }

    const enviraCarrito = () => {
        if(productos){
            productos.map((item, i) => {
                var carrito = props.carrito || {}; 
                carrito[item.codigoSku] = {
                    id: item.id,
                    nombre: item.nombre,
                    precio: parseFloat(item.precio),
                    iva: parseInt(item.iva),
                    ico: parseInt(item.ico),
                    cantidad: parseInt(item.cantidad)
                }
                Auth.setCarrito(carrito);
                props.ActualizarCarrito(Auth.getCarrito());
            })

            setAlerta({
                tipo: "success",
                titulo: "Pack Agregado Correctamente.",
                mensaje: "Se agrego el pack postobon correctamente.",
                btnOk: "Aceptar",
                fnOk: function () {
                    props.history.push('/carrito'); 
                }
            }) 

        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.match.params.pack){
            var urlPack = props.match.params.pack;
            var packArray = urlPack.split("-");
            var packId = packArray[packArray.length - 1];
            loadProducto(packId);
        }
    }, [props.match.params.pack])

    return (
        <React.Fragment>
           <div className="content-carrito">
                <div className="navbar principal navcarrito">
                    <Navbar item={2}/>
                    <Sub_nav/>
                </div>
            </div>
            <div className="layout">
                <div className="pack">
                    <div className="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Inicio</a></li>
                            <li className="breadcrumb-item active">Combo Postobón</li>
                        </ol>
                    </div>
                    <div className="pack-product">
                        <div className="pack-content pedido-list">
                            <div className="pack-productos">
                                {productos && (
                                    <div className="product-list">
                                        {productos.map((item, i) => (
                                            <div className="item item-carrito" key={i}>
                                                <img 
                                                    className="img-product"
                                                    src={ Constants.URL_BASE +'image/'+ item.codigoSku + '.jpg'}
                                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                </img>
                                                <div className="start">
                                                
                                                    <p className="nombre">{item.nombre}</p>
                                                    <p className="precio">
                                                        {" $" + new Intl.NumberFormat("en", {
                                                            numberingSystem: "latn", 
                                                            style: "decimal",
                                                            currency: "COP"
                                                        }).format(item.precio)}
                                                    </p>
                                                    <p className="precio">
                                                        Cantidad {item.cantidad}
                                                    </p>
                                                    <p className="regular">
                                                        Total 
                                                        {" $" + new Intl.NumberFormat("en", {
                                                            numberingSystem: "latn", 
                                                            style: "decimal",
                                                            currency: "COP"    
                                                        }).format(item.cantidad * item.precio)} 
                                                    </p>
                                                </div>
                                                {/* <div className="end">
                                                    <div className="action">
                                                        <div className="controls">                                            
                                                            <button role="button" onClick={() => handleMenos(item, props.carrito[item])}>
                                                                <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                                            </button>
                                                            <input type="number" minLength="0" maxLength="99"
                                                                    onChange={e => handleChangeCantidad(item, props.carrito[item], e.target.value)}
                                                                    value={props.carrito [item].cantidad} />
                                                            <button role="button" onClick={e => handleMas(item, props.carrito[item])}>
                                                                <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                                            </button>
                                                        </div>
                                                        <a style={{cursor:"pointer"}} onClick={e => handleEliminar(item)}>
                                                            <img src={eliminar} alt="icon delete" width="30px" height="30px"></img>
                                                        </a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        ) )}
                                    </div>
                                )}
                            </div>
                            <div className="pack-info">
                                <h2 className="pack-title"><b>Combo Postobón</b></h2>
                                <p className="pack-description">Combo recomendado</p>  

                                <div className="pack-additional">
                                    <p>Si haces tu pedido antes de las 4pm será entregado mañana</p>
                                    <p className="envio">Envio: Gratis</p>
                                </div>

                                { mayor ? (
                                    <div className="alertas">
                                        <p>EL EXCESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD. LEY 30 DE 1986. PROHÍBASE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. LEY 124 DE 1994.</p>
                                    </div>
                                ) : null }

                                { catorce ? (
                                    <div className="alertas">
                                        <p>CONTENIDO ELEVADO EN CAFEÍNA 32MG/100 ML. LA BEBIDA ENERGIZANTE NO PREVIENE LOS EFECTOS GENERADOS POR EL CONSUMO DE BEBIDAS ALCOHÓLICAS. NO SE RECOMIENDA EL CONSUMO DE BEBIDAS ENERGIZANTES CON BEBIDAS ALCOHÓLICAS. ESTE PRODUCTO SOLO PODRÁ SER COMERCIALIZADO, EXPENDIDO Y DIRIGIDO A POBLACIÓN MAYOR DE 14 AÑOS. ESTE PRODUCTO NO ES RECOMENDADO PARA PERSONAS SENSIBLES A LA CAFEÍNA (ART. 13 – RESOLUCIÓN 4150 DE 2009).</p>
                                    </div>
                                ) : null }

                                <p className="pack-price">
                                    {"$ " + new Intl.NumberFormat("en", {
                                        numberingSystem: "latn", 
                                        style: "decimal",
                                        currency: "COP"
                                    }).format(total || 0)}
                                </p>          

                                <div className="action">
                                    <button className="agregar" onClick={() => handleMas(cantidad)}>Agregar</button>
                                    {/* { cantidad > 0 ? (
                                        <div className="controls">
                                            <button role="button" onClick={() => handleMenos(null)}>
                                                <img src={minus} alt="icon minus" width="15px" height="15px"></img>
                                            </button>
                                            <input type="number" minLength="0" maxLength="99"
                                                onChange={e => handleChangeCantidad(null, e.target.value)}
                                                value={cantidad}/>
                                            <button role="button" onClick={() => handleMas(null)}>
                                                <img src={plus} alt="icon plus" width="15px" height="15px"></img>
                                            </button>
                                        </div>
                                    ) : (
                                        <button className="agregar" onClick={() => handleMas(cantidad)}>Agregar</button>
                                    )}  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
            <Footer />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state),
      carrito: selectActiveCarrito(state)
    }
}

export default  withRouter(connect(mapStateToProps, {ActualizarCarrito}) (Pack));
