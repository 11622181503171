import React, { useEffect } from "react"
import ContentLoader from "react-content-loader"

const CargarContenido = (props) => {


    return (
        <div className="cargando">
            { props.carrito == 1 ?
                <ContentLoader
                    speed={2}
                    className="cargandoPrecios"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="4" rx="3" ry="3" className="itemsPrecio1" height="10" />
                    <rect x="0" y="20" rx="3" ry="3" className="itemsPrecio2" height="10" />
                    <rect x="0" y="36" rx="3" ry="3" className="itemsPrecio3" height="10" />
                    <rect x="0" y="52" rx="3" ry="3" className="itemsPrecio3" height="10" />
                    <rect x="0" y="68" rx="3" ry="3" className="itemsPrecio4" height="10" />
                    <rect x="0" y="84" rx="3" ry="3" className="itemsPrecio4" height="10" />
                </ContentLoader>
                : <React.Fragment>
                    {props.carrito == 2 ? 
                            <ContentLoader
                                className="cargandoItem"
                                speed={2}
                                height={28}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb">
                                <rect className="itemsRect1" x="0" y="5" rx="3" ry="3" height="11" />
                                <rect className="itemsRect2" x="0" y="18" rx="3" ry="3" height="11" />
                            </ContentLoader>
                            : 
                            <ContentLoader
                                className="cargandoinfoUsuario"
                                speed={2}
                                width = {100}
                                height={30}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#ecebeb">
                                <rect className="infoUsuario" x="0" y="5" rx="3" ry="3" height="10" />
                                <rect className="infoUsuario2" x="0" y="18" rx="3" ry="3" height="10" />
                            </ContentLoader>
                    }
                </React.Fragment>
            }
        </div>

    )
}

export default CargarContenido