import firebase from 'firebase/app';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyAoVsS6gYDenUtprgpRa6joLir3n7OcFZQ",
    authDomain: "team-b2b-48a68.firebaseapp.com",
    projectId: "team-b2b-48a68",
    storageBucket: "team-b2b-48a68.appspot.com",
    messagingSenderId: "490098695976",
    appId: "1:490098695976:web:5de7344329e328c71f3a1d",
    measurementId: "G-BC9GWZQ7C7"
};

var fire = firebase.initializeApp(firebaseConfig);

firebase.analytics();

export default fire;