import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Constants from '../../constants/global';
import axios from 'axios';

// REDUX
import { selectActiveCarrito } from '../../store/carrito/reducer';
import ActualizarCarrito from '../../store/carrito/action';

import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';

import address_blue from '../../assets/icon/address_blue.svg';

const CoberturaModal = (props) => {

    const [alerta, setAlerta] = useState();
    const [error, setError] = useState({});
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState("NONE");


    const handleChangeSuc = (item) => {
        setSucursal(item);
    }

    const handleClose = () => {
        props.showModal(false);
        if(document.querySelector(".modal-cobertura")!=null){
            document.querySelector(".modal-cobertura").classList.remove("show-modal");
        }
    }

    const handleSaveLocation = () => {
        var err = {};
        if (sucursal == "NONE") {
            err['Sucursal'] = "La Sucursal es obligatorio.";
            setError(err);
        }


        if(Object.keys(err).length == 0) {
            var sucu = JSON.parse(sucursal);
            
            Auth.setSucursal({
                ciudad: sucu.ciudad,
                codListaP: sucu.codListaP,
                codigoSac: sucu.codigoSac,
                direccion: sucu.direccion,
                nombre: sucu.nombre,
                razonSocial: sucu.razonSocial,
                nit: sucu.nit,
                condicionPago: sucu.condicionPago,
                cupo: sucu.cupo,
                codDiasEntrega: sucu.codDiasEntrega,
                pedidoMinimo: sucu.pedidoMinimo,
                pedidoMaximo: sucu.pedidoMaximo,
                bloqueado: sucu.bloqueado,
                agencia :  sucu.agencia,
                grupoCliente :  sucu.grupoCliente,
                obsequiPrimerPed : sucu.obsequiPrimerPed,
                bloqueadoCartera: 'N'
            });
            props.updateSucursal(sucu);
            if(sucu.bloqueado=="S"){
                setAlerta({
                    tipo: "error",
                    titulo: "Cliente bloqueado",
                    mensaje: "Lastimosamente en este momento presentas un bloqueo y no podrás tomar tu pedido. Te invitamos a navegar nuestra página y conocer todo lo que tenemos para ti. ¡Estamos a la orden!",
                    btnOk: "Aceptar",
                    fnOk: function () { 
                        refresh();
                    },
                    fnCancel: null
                })
            }else{
                refresh();
            }
        }

    }

    const refresh = () => {
        handleClose();
        Auth.setCategorias({})
        Auth.removeCarrito();
        props.ActualizarCarrito({});
        //handleRedirect('/');
        window.location.reload(true);
    }

    const handleRedirect = item => {
        props.history.push(item);
    }


    useEffect(() => {
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
        setSucursales(props.sucursales)
    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">
                    <div className="content">
                        <div className="alert-content">
                            <img src={address_blue} alt="icon menu" width="60px" height="60px"></img>
                            <p className="alert-title">Bienvenido</p>
                            <p className="alert-message">Por favor seleccione la sucursal.</p>
                        </div>

                        <form>
                            <div className="form-control">
                                <label>Sucursales <span>*</span>:</label>
                                <select
                                    className={error.Sucursal ? "input-error" : null}
                                    name="sucursales"
                                    value={sucursal}
                                    onChange={e => handleChangeSuc(e.target.value)}>
                                    <option selected="true" value="NONE">Seleccione sucursal</option>
                                    {sucursales.map((item, i) => (
                                        <option key={i} value={JSON.stringify(item)}> {item.codigoSac +" - "+item.direccion} </option>
                                    ))}
                                </select>
                                {error.Sucursal ? (
                                    <span className="error">
                                        <b>{error.Sucursal}</b>
                                    </span>
                                ) : null}
                            </div>


                            <button type="button" className="btn-large" onClick={e => handleSaveLocation()}>Aceptar</button>
                            <button type="button" className="btn-large outline" onClick={e => handleClose()}>Cancelar</button>
                        </form>
                    </div>
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        carrito: selectActiveCarrito(state)
    }
}


export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(CoberturaModal));

//export default withRouter(CoberturaModal);