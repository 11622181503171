import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import axios from 'axios';
import * as Constants from '../../constants/global';
import Auth from '../../helpers/Auth';

//REDUX
import ActualizarUsuario from '../../store/usuario/action';
import {selectActiveUser} from '../../store/usuario/reducer';

//IMAGE
import close from '../../assets/icon/close_grey.svg';
import logo from '../../assets/img/logo.png';
import error from '../../assets/icon/error.svg';


const SucursalModal = (props) => {

    let carrito = JSON.parse(localStorage.getItem('carrito'));
    const[deleteItem, setDeleteItem] = useState()    

    const handleClose = () => {
        if(props.mayorEdad == 1 || props.mayorEdad == 2)
            window.location.reload(true);

        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const handleEliminar = () => {
        props.callback(props.producto)
        handleClose()
    }

    const handleEliminarPedido = () => {
        localStorage.setItem('carrito', null)
        props.history.replace('/', "urlhistory")
        handleClose()
    }

    const handleSiMayor = () => {
        var cliente = props.usuario;
        if(cliente){
            let fa=new Date();
            let fe=new Date(fa.getFullYear(),fa.getMonth(),fa.toLocaleString('es-CO',{day:'numeric'}),fa.getHours(), fa.getMinutes(),fa.getMilliseconds());
            axios.put(Constants.URL_BASE + Constants.WSACTUALIZARMAYOR + cliente.id, {
                'fechaMayorEdad': fe.toISOString()
            },{
                headers:{
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }).then((response)=>{
                cliente.fechaMayorEdad = fe.toISOString();
                props.ActualizarUsuario(cliente);
                document.querySelector(".modal-sucursal").classList.remove("show-modal");
            }).catch((err)=>{
            })
        } 
    }

    const handleSiCatorce = () => {
        var cliente = props.usuario;
        if(cliente){
            let fa=new Date();
            let fe=new Date(fa.getFullYear(),fa.getMonth(),fa.toLocaleString('es-CO',{day:'numeric'}),fa.getHours(), fa.getMinutes(),fa.getMilliseconds());
            axios.put(Constants.URL_BASE + Constants.WSACTUALIZARCATORCE + cliente.id, {
                'fechaMayorEdad': fe.toISOString()
            },{
                headers:{
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }).then((response)=>{
                cliente.fechaMayor14 = fe.toISOString();
                props.ActualizarUsuario(cliente);
                document.querySelector(".modal-sucursal").classList.remove("show-modal");
            }).catch((err)=>{
                
            })
        } 
    }

    const handleNoMayor = () => {
        window.location.reload(false);
        handleClose();
    }

    return (
        <div className="modal modal-sucursal">            
                <div className="modal-content">       
                    <div className="title">

                        <p>{props.mayorEdad == 1 ? "¿Usted es mayor de edad?" : (props.mayorEdad == 2 ? "¿Usted es mayor de 14 años?" : "Eliminar producto(s)")} </p>
                        <span className="close-button" onClick={() => handleClose()}>
                            <img 
                                src={close} 
                                width="15" 
                                height="15" 
                                alt="icon close"/>
                        </span>  
                    </div>
                    <div className="content">
                        {props.producto ? (
                            <React.Fragment>
                                <label>¿Está seguro de eliminar <b style={{"text-decoration": "underline"}}>{carrito[props.producto] ? (carrito[props.producto].nombre) : ('')}</b>?</label>
                                <img 
                                    className="img-modal"
                                    src={ Constants.URL_BASE +'/image/' + (props.producto || '' )+ '.jpg'}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=logo}}>
                                </img>

                                <label>Cantidad: {(carrito[props.producto] ? (carrito[props.producto].cantidad) : (''))}</label>

                                <div className="margin">
                                    <button type="submit" className="btn-large" onClick={e => handleEliminar()}>Si, eliminar</button>
                                </div>
                                <button type="button" className="btn-large outline" onClick={handleClose}>No, cancelar</button>
                            </React.Fragment>
                        ) : (
                            null
                        )}

                        {props.cancelarPedido ? (
                            <React.Fragment>
                                <b><label>Esta seguro de cancelar el pedido     </label></b>
                                <b><label> Se perderan todos los productos.</label></b>                               

                                <img 
                                    className="img-modal"
                                    src={error}>
                                </img>


                                <div className="margin">
                                    <button type="button" className="btn-large" onClick={() => handleEliminarPedido()} >Si, Eliminar</button>
                                </div>
                                <button type="button" className="btn-large outline" onClick={handleClose}>No, cancelar</button>
                            </React.Fragment>
                        ) : (
                            null
                        )}

                        {props.mayorEdad == 1 ? (
                            <React.Fragment>
                                <b><label>Recuerde que este tipo de productos solo puede ser vendidos a mayores de edad.</label></b>                               

                                <img 
                                    className="img-modal"
                                    src={error}>
                                </img>


                                <div className="margin">
                                    <button type="button" className="btn-large" onClick={() => handleSiMayor()} >Si</button>
                                </div>
                                <button type="button" className="btn-large outline" onClick={() => handleNoMayor()}>No</button>
                            </React.Fragment>
                        ) : (
                            null
                        )}

                        {props.mayorEdad == 2 ? (
                            <React.Fragment>
                                <b><label>Recuerde que este tipo de productos solo puede ser vendidos a mayores de 14 años.</label></b>                               

                                <img 
                                    className="img-modal"
                                    src={error}>
                                </img>


                                <div className="margin">
                                    <button type="button" className="btn-large" onClick={() => handleSiCatorce()} >Si</button>
                                </div>
                                <button type="button" className="btn-large outline" onClick={() => handleNoMayor()}>No</button>
                            </React.Fragment>
                        ) : (
                            null
                        )}
                    </div>
                </div>               
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToProps, {ActualizarUsuario}) (SucursalModal));