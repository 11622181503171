import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import firebase from '../../constants/firebase';
import * as Constants from '../../constants/global';
import close from '../../assets/icon/close_grey.svg';
import axios from 'axios';
import Progress from '../util/Progress';

import error from '../../assets/icon/error.svg';


const DetallePedido = (props) => {

    const [detalle, setDetalle] = useState();
    const [pedido, setPedido] = useState();
    const [loader, setLoader] = useState(false);
    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [estado, setEstado] = useState(1);

    const handleClose = () => {
        props.callback(true)
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
        props.updatePedidos();
    }

    const calcularPrecio = (lista) => {
        let total = 0;
        let descuento = 0;
        let iva = 0;
        lista.map((item) => {
            let desProducto = item.precio * (item.descuento / 100);
            let ivaProducto = (item.precio - desProducto) * (item.iva / 100);

            descuento += item.cantidad * desProducto;
            iva += item.cantidad * ivaProducto;
            total += item.cantidad * ((item.precio - desProducto) + ivaProducto);

            item.descuento = desProducto;
            item.iva = ivaProducto;

        })
        setTotal(total);
        setDescuento(descuento);
        setIva(iva);
    }

    const getDetalle = () => {
        setLoader(true)
        let url = Constants.URL_BASE + Constants.WSDETALLEPEDIDO + props.pedido.id
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            setDetalle(response.data)
            setTimeout(() => {
                setLoader(false)
                calcularPrecio(response.data);
            }, 2000)
        }).catch((err) => {

        });
    }

    const handleCancelar = () => {
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSCANCELARPEDIDO;
        axios.post(url, {
            "numeroDoc": pedido.numeroDoc
        },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                setLoader(false);
                setEstado(4);
            }).catch((err) => {
                setLoader(false);

            })
    }

    useEffect(() => {
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        setPedido(props.pedido);
        setEstado(props.pedido.estadoId);
        getDetalle();
    }, [])


    return (
        <div className="modal modal-sucursal">
            <div className="modal-content table-content">
                <div className="title">
                    <p>Número de orden: {pedido ? (pedido.numeroDoc) : (null)}</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img
                            src={close}
                            width="15"
                            height="15"
                            alt="icon close" />
                    </span>
                </div>
                <div className="content">
                    <div className="list-sucursales">
                        {detalle ? (
                            <React.Fragment>
                                {loader ? <Progress color={"#0194C5"}></Progress> : (
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="mytabla">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Nombre</th>
                                                        <th className="numero">Cantidad</th>
                                                        <th className="numero">Iva</th>
                                                        <th className="numero">Descuento</th>
                                                        <th className="numero">Precio</th>
                                                        <th className="numero">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-hover">
                                                    {detalle.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text-left">{item.nombre}</td>
                                                            <td className="numero">{item.cantidad}</td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                    currency: "COP"
                                                                }).format(item.iva)}
                                                            </td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                    currency: "COP"
                                                                }).format(item.descuento)}
                                                            </td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                    currency: "COP"
                                                                }).format(item.precio)}</td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                    currency: "COP"
                                                                }).format((item.cantidad * ((item.precio - item.descuento) + item.iva)))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="5" className="numero"><b>Subtotal:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                style: "decimal",
                                                                maximumFractionDigits: 0,
                                                                currency: "COP"
                                                            }).format(total - iva + descuento)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" className="numero"><b>Iva:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                maximumFractionDigits: 0,
                                                                style: "decimal",
                                                                currency: "COP"
                                                            }).format(iva)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" className="numero"><b>Descuento:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                style: "decimal",
                                                                maximumFractionDigits: 0,
                                                                currency: "COP"
                                                            }).format(descuento)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" className="numero"><b>Envío:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                style: "decimal",
                                                                maximumFractionDigits: 0,
                                                                currency: "COP"
                                                            }).format(props.pedido.valorEnvio)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5" className="numero"><b>Total:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                style: "decimal",
                                                                maximumFractionDigits: 0,
                                                                currency: "COP"
                                                            }).format((total+props.pedido.valorEnvio))}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="panel-button">
                                            <button className="btn-aceptar" onClick={() => handleClose()}>Cerrar</button>
                                            {estado == 1 ? (
                                                <button className="btn-aceptar" onClick={() => handleCancelar()}>Cancelar pedido</button>
                                            ) : null}
                                            {estado == 4 ?
                                                (
                                                    <h3 style={{ color: "red" }}>Este pedido ha sido cancelado</h3>
                                                ) : null}
                                        </div>

                                    </React.Fragment>
                                )}

                            </React.Fragment>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetallePedido);