import React, { useState } from 'react'
import { Space, Checkbox, Tag } from 'antd'
import "antd/dist/antd.css";

const Filter = ({ color, colorText, lg, filtros, top = '215px', handleFiltro }) => {

    const [toggle, setToggle] = useState(true);
    const [filtro, setFiltro] = useState([]);
    const [filtroView, setFiltroView] = useState([]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const isChecked = (tipo, id) => {
        let exist = false;
        filtro.forEach((item, i) => {
            if (item.tipo == tipo && item.id == id) {
                exist = true;
            }
        })
        return exist;
    }

    const limpiarFiltro = () => {
        setFiltro([]);
        setFiltroView([]);
        handleFiltro([]);
    }

    const addFiltro = (tipo, id, name) => {
        
        let filtroAux = [...filtro];
        let exist = false;
        filtro.forEach((item, i) => {
            if (item.tipo == tipo && item.id == id) {
                exist = true;
                filtroAux.splice(i, 1);
            }
        })

        if (!exist) {
            filtroAux.push({
                tipo: tipo,
                id: id,
                name: name
            })
        }

        setFiltro(filtroAux);
    }

    const filtrar = () => {
        setFiltroView(filtro)
        handleFiltro(filtro)
    }


    return (
        <div className="contenedor_filter">
            {filtroView.length > 0 && (
                <>
                    <div className="title">
                        <h2 style={{
                            borderColor: color
                        }}>
                            Filtros
                        </h2>
                    </div>
                    <div
                        style={{
                            marginTop: '10px'
                        }}>
                        {filtroView.map((item, i) => (
                            <Tag color="magenta">
                                {item.name}
                            </Tag>
                        ))}
                    </div>
                    <button
                        type="button"
                        className="btn-large outline"
                        onClick={() => limpiarFiltro()}
                        style={{
                            marginTop: '10px',
                            borderColor: color,
                            backgroundColor: color,
                            color: colorText
                        }}>
                        Limpiar
                    </button>
                </>
            )}
            <Space direction="vertical" style={{
                marginTop: '12px'
            }}>
                {filtros && Object.keys(filtros).map((item, i) => (
                    <>
                        {console.log(filtros)}
                        {filtros[item].length > 0 && (
                            <Space direction="vertical" key={i} style={{
                                marginTop: '15px'
                            }}>
                                <div className="title">
                                    <h2 style={{
                                        borderColor: color
                                    }}>
                                        {capitalizeFirstLetter(item)}
                                    </h2>
                                </div>
                                <Space direction="vertical" style={{
                                    marginTop: '15px'
                                }}>
                                    {filtros[item].map((filtro, j) => (
                                        <Checkbox
                                            style={{
                                                "--background-color": color || '#2098de',
                                                "--border-color": color || '#2098de',
                                            }}
                                            key={filtro.id}
                                            checked={isChecked(item, filtro.id)}
                                            onClick={() => addFiltro(item, filtro.id, filtro.descripcion)}>

                                            {filtro.descripcion}
                                        </Checkbox>
                                    ))}
                                </Space>
                            </Space>
                        )}
                    </>
                ))}
            </Space>

            <Space direction="vertical" style={{
                width: '100%',
                marginTop: '15px',
                paddingTop: '15px',
                borderTop: '1px solid #ebebeb'
            }}>
                {filtros && (
                    <button
                        type="button"
                        className="btn-large"
                        style={{
                            borderColor: color,
                            backgroundColor: color,
                            color: colorText
                        }}
                        onClick={() => filtrar()}>
                        Aplicar
                    </button>
                )}


                {lg && (
                    <button
                        type="button"
                        className="btn-large outline"
                        style={{
                            borderColor: color,
                            color: color
                        }}
                        onClick={() => setToggle(!toggle)}>
                        Cerrar
                    </button>
                )}

            </Space>
        </div>
    )
}

export default Filter
