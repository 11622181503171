import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useState } from 'react'
import Maqueta from '../maqueta/Maqueta';
import axios from 'axios';
import * as Constants from '../../constants/global';
import AlertModal from '../modal/AlertModal';
import { BsEyeSlash } from 'react-icons/bs';
import pdfTerminos from '../../assets/terminos/pdfTerminos.pdf';
import pdfPoliticaTratamiento from '../../assets/terminos/pdfPoliticaTratamiento.pdf';
import novisibility from '../../assets/icon/hidden.svg';
import visibility from '../../assets/icon/visibility.svg';
import { useHistory } from 'react-router-dom';

export const RegistroNuevo = () => {

    const initialvalues = {
        nombre: '',
        apellido: '',

        cedula: ''
    }
    const history = useHistory();
    const [habilitarCampo, setHabilitarCampo] = useState(false);
    const [tipoNotificicacion, setTipoNotificicacion] = useState(false);
    const [moveLogin, setmoveLogin] = useState(0)
    const [form, setform] = useState(initialvalues);
    const [alerta, setAlerta] = useState();
    const [error, setError] = useState("");
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [barrios, setBarrios] = useState([]);
    const [email, setEmail] = useState("");
    const [formulario, setFormulario] = useState(false);
    const [departamento, setDepartamento] = useState("NONE");
    const [municipio, setMunicipio] = useState("NONE");
    const [direccion, setDireccion] = useState();
    const [hidden, setHidden] = React.useState(false);
    const [emailValidar, setEmailValidar] = useState("");
    const [barrio, setBarrio] = useState();
    const [errorg, setErrorg] = useState("");
    const [notificaciones, setNotificaciones] = useState("Seleccione");
    const [complemento, setComplemento] = useState("");
    const [celular, setCelular] = useState("");
    const [celularValidar, setCelularValidar] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const arrayTipo2 = ["Seleccione", "Correo electrónico", "Celular"];
    const arrayTipo = ["Calle", "Carrera", "Avenida", "Avenida(carrera)", "Avenida(calle)",
        "Circular", "Circunvalar", "Diagonal", "Manzana", "Transversal", "Via"];
    const [dir1, setDir1] = useState("Calle");
    const [dir2, setDir2] = useState("");
    const [dir3, setDir3] = useState("");
    const [dir4, setDir4] = useState("");
    const [departamentoId, setDepartamentoId] = useState(0)
    const [municipioId, setMunicipioId] = useState(0)
    const [terminos, setTerminos] = useState();
    const [habeas, setHabeas] = useState();

    const { nombre, apellido, cedula } = form;


    const validarIpunt = (item) => {
        var err = {};
        if (item !== email && tipoNotificicacion) {
            err['Email'] = "El correo electrónico debe coincidir.";
        } else if (item !== celular && !tipoNotificicacion) {
            err['Celular'] = "El celular debe coincidir.";
        }

        setError(err);


        if (tipoNotificicacion) {
            setEmailValidar(item);
        } else {
            setCelularValidar(item);
        }
    }
    const handleregister = (e) => {
        e.preventDefault();
        setError("")
        var err = error || {};
        if (departamento == "NONE") {
            err['Departamento'] = "El departamento es obligatorio.";
            return setError(err);
        }

        if (municipio == "NONE") {
            err['Municipio'] = "El municipio es obligatorio.";
            return setError(err);
        }
        if (dir2.length < 1) {
            err['numero1'] = "El numero es obligatorio.";
            return setError(err);
        }
        if (dir3.length < 1) {
            err['numero2'] = "El complemento es obligatorio.";
            return setError(err);
        }

        setErrorg("");
        setError("");
        var muni = JSON.parse(municipio);
        var respDep = JSON.parse(departamento);
        const { codigo: depart } = respDep;
        var nota = complemento.length > 0 ? complemento : ""
        const { codigo: ciudad } = muni;
        // var muni = JSON.parse(municipio);
        var nota = complemento.length > 0 ? complemento : ""
        var direccion = "" + dir1 + " " + dir2 + " N° " + dir3 + "-" + dir4 + ", " + nota

        let clienteErp = {
            nombre,
            apellido,
            cedula,
            direccion,
            ciudad,
            depart,
            "tipoDoc": "CC",
            "telefono": celular,
            "email": email,
            "clave": contrasena,
            "tyC": Number(terminos) || 0,
            "habeasData": Number(habeas) || 0,
        }
        sendNewClient(clienteErp);
    }

    const sendNewClient = async (clientErp = {}) => {
        const url = Constants.URL_BASE + Constants.WSCLIENTE_ERP;
        await axios.post(url, clientErp).then(({ data }) => {
            const { status } = data;
            if (status === "succes") {
                setAlerta({
                    tipo: "success",
                    titulo: "Registro usuario",
                    mensaje: "Registro exitoso",
                    btnOk: "Aceptar",
                    fnOk: function () {
                        history.push('/login');
                    },
                    fnCancel: null
                })
            }
            else {
                return setError("Error al enviar la informacion");
            }
        }).catch(error=>
            {
               const resp = error.response.data.errors.Clave|| ["Error, comunicarse con el administrador"]
               console.log(resp)
               setAlerta({
                tipo: "error",
                titulo: "Error",
                mensaje: `${resp[0]}`,
                btnOk: "Aceptar",                
                fnCancel: null
            })
            });
        // setmoveLogin(1);
    }


    useEffect(() => {
        getDepartamentos();
    }, [])

    const handleChangeNotificacion = (item) => {
        setEmail("");
        setEmailValidar("");
        setCelular("");
        setCelularValidar("");

        if (item !== "Seleccione") {
            setFormulario(true);
            if (item == "Celular") {
                setTipoNotificicacion(false);
            } else {
                setTipoNotificicacion(true);
            }
            setNotificaciones(item);
        } else {
            setFormulario(false);
            setNotificaciones(item);
        }
    }

    const handleChangeDep = (item) => {
        var depto = JSON.parse(item);
        console.log(depto);
        if (item !== "NONE") {
            setDepartamento(item);
            setMunicipio("NONE");
            setDepartamentoId(depto.codigo)
            getMunicipios(depto.codigo);
            setDireccion("");
            setBarrio("");
        } else {
            setDepartamento(item);
            setMunicipios([]);
            setMunicipio("NONE");
            setDireccion("");
            setBarrio("");
        }
    }


    const handleChangeMun = (item) => {
        if (item !== "NONE") {
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
            getBarrios(JSON.parse(item).id);
            setMunicipioId(JSON.parse(item).id)
        } else {
            getBarrios([]);
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
        }
    }

    const getDepartamentos = () => {
        let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=1"
        axios.get(url).then(res => {
            let data = res.data || [];
            setDepartamentos(data);
        })
    }

    const getMunicipios = (departamentoId) => {
        let url = Constants.URL_BASE + Constants.WSMUNICIPIO + departamentoId + "?co=1"
        axios.get(url).then(res => {
            let data = res.data || [];
            setMunicipios(data);
        })
    }

    const getBarrios = (municpioId) => {
        let url = Constants.URL_BASE + Constants.WSBARRIO + municpioId;
        axios.get(url).then(res => {
            let data = res.data || [];
            setBarrios(data);
        })
    }
    const handleOnchange = ({ target }) => {
        setform({ ...form, [target.name]: target.value });

    }

    const handleClose = () => {
        setform(initialvalues);
        setmoveLogin(2);
    }

    const onChangeInput = (item) => {

        if (item.length > 0) {
            setHabilitarCampo(true);
        } else {
            setHabilitarCampo(false);
            setCelularValidar("");
            setEmailValidar("");
        }

        if (tipoNotificicacion) {
            setEmail(item);
        } else {
            setCelular(item);
        }


    }
    const handleCheckbox = (check) => {
        var checkbox = document.getElementById(check);
        checkbox.addEventListener("change", validaCheckbox, false);

        function validaCheckbox() {
            var checked = checkbox.checked;
            if (checked) {
                if (check == "terminos_condiciones") {
                    setTerminos(1);
                    setHabeas(1);
                }

            } else {
                if (check == "terminos_condiciones") {
                    setTerminos();
                    setHabeas();
                }

            }
        }
    }
    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">
                    <div className="main">
                        <h3 className="large">Registro</h3>
                        <form onSubmit={handleregister}>
                            <div className="form-control">
                                <label>Nombre:</label>
                                <input type="text" name="nombre" value={nombre} placeholder="Nombre completo" onChange={handleOnchange} required autocomplete="off"></input>

                            </div>
                            <div className="form-control">
                                <label>Apellidos:</label>
                                <input type="text" name="apellido" value={apellido} placeholder="apellidos" onChange={handleOnchange} required autocomplete="off"></input>

                            </div>
                            <div className="form-control">
                                <label>Cedula:</label>
                                <input type="number" name="cedula" value={cedula} placeholder="Cedula" onChange={handleOnchange} required autocomplete="off"></input>

                            </div>
                            <div className="form-control">
                                <label>Departamento:</label>
                                <select
                                    className={error.Departamento ? "input-error" : null}
                                    name="departamentos"
                                    value={departamento}
                                    onChange={e => handleChangeDep(e.target.value)}>
                                    <option selected="true" value="NONE">Seleccione departamento</option>
                                    {departamentos.map((item, i) => (
                                        <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                    ))}
                                </select>
                                {error.Departamento ? (
                                    <span className="error">
                                        <b>{error.Departamento}</b>
                                    </span>
                                ) : null}
                            </div>

                            {municipios ? (
                                <div className="form-control">
                                    <label>Municipio:</label>
                                    <select
                                        className={error.Municipio ? "input-error" : null}
                                        name="municipios"
                                        value={municipio}
                                        onChange={e => handleChangeMun(e.target.value)}>
                                        <option selected="true" value="NONE">Seleccione un municipio</option>
                                        {municipios.map((item, i) => (
                                            <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                        ))}
                                    </select>
                                    {error.Municipio ? (
                                        <span className="error">
                                            <b>{error.Municipio}</b>
                                        </span>
                                    ) : null}
                                </div>
                            ) :
                                <div className="form-control">
                                    <label>Municipio:</label>
                                    <select name="municipios" >
                                        <option>Seleccione un municipio</option>
                                    </select>
                                </div>
                            }

                            {departamento !== "NONE" && municipio !== "NONE" ? (
                                <div className='form-control'>

                                    <label>Dirección <span>*</span>:</label>
                                    <div className="input-direcion">
                                        <select defaultValue={dir1} onChange={e => setDir1(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                                            {arrayTipo.map((item, i) => (
                                                <option key={i} value={item} selected={dir1 === item}>{item}</option>
                                            ))}
                                        </select>
                                        <div className="direccion-group">
                                            <input
                                                className={error.numero1 ? "input-error" : null}
                                                name="numero1"
                                                type="text"
                                                placeholder="--"
                                                value={dir2}
                                                onChange={e => setDir2(e.target.value)}>
                                            </input>
                                            <label>#</label>
                                            <input
                                                className={error.numero2 ? "input-error" : null}
                                                name="numero2"
                                                type="text"
                                                placeholder="--"
                                                value={dir3}
                                                onChange={e => setDir3(e.target.value)}
                                            >
                                            </input>
                                            <label>-</label>
                                            <input type="text" placeholder="--" value={dir4} onChange={e => setDir4(e.target.value)} className={error.length > 0 ? "input-error" : null}></input>
                                        </div>
                                        <div className="form-control">
                                            <label>Piso / Oficina / Apto:</label>
                                            <input
                                                type="text"
                                                placeholder="Ingrese complemento"
                                                value={complemento} onChange={e => setComplemento(e.target.value.replace("-", ""))}>
                                            </input>
                                        </div>

                                        {error.length > 0 ? (
                                            <span className="error">
                                                <b>{error}</b>
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-control">
                                <label>Metodo principal notificaciones: <span>*</span></label>
                                <div className="input-notificacion">
                                    <select defaultValue={notificaciones} onChange={e => handleChangeNotificacion(e.target.value)} className={error.length > 0 ? "input-error" : null}>
                                        {arrayTipo2.map((item, i) => (
                                            <option key={i} value={item} selected={notificaciones == item}>{item}</option>
                                        ))}
                                    </select>
                                    {error.Notificacion ? (
                                        <span className="error">
                                            <b>{error.Notificacion}</b>
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            {!formulario ? null :
                                (
                                    <React.Fragment>
                                        {tipoNotificicacion ?
                                            (
                                                <div className="form-control">
                                                    <label>Correo: <span>*</span></label>
                                                    <input
                                                        id="email"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        value={email}
                                                        placeholder="Dirección de correo electrónico"
                                                        onChange={e => onChangeInput(e.target.value)}></input>


                                                    <label>Validar Correo: <span>*</span></label>
                                                    <input
                                                        id="emailValidar"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        disabled={!habilitarCampo ? "disabled" : ""}
                                                        placeholder="Dirección de correo electrónico"
                                                        value={emailValidar}
                                                        onChange={e => validarIpunt(e.target.value)}></input>
                                                    {error.Email ? (
                                                        <span className="error">
                                                            <b>{error.Email}</b>
                                                        </span>
                                                    ) : null}


                                                    <label>Celular:</label>

                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celular"
                                                        value={celular}
                                                        onChange={e => setCelular(e.target.value)}></input>

                                                </div>
                                            ) :
                                            (
                                                <div className="form-control">
                                                    <label>Celular: <span>*</span></label>

                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celular"
                                                        value={celular}
                                                        onChange={e => onChangeInput(e.target.value)}></input>


                                                    <label>Validar Celular: <span>*</span></label>
                                                    <input
                                                        className={error.Telefono ? "input-error" : null}
                                                        type="number"
                                                        placeholder="Celular"
                                                        pattern="^[0-9]+"
                                                        min="0"
                                                        id="celularValidar"
                                                        value={celularValidar}
                                                        disabled={!habilitarCampo ? "disabled" : ""}
                                                        onChange={e => validarIpunt(e.target.value)}></input>
                                                    {error.Celular ? (
                                                        <span className="error">
                                                            <b>{error.Celular}</b>
                                                        </span>
                                                    ) : null}


                                                    <label>Correo:</label>
                                                    <input
                                                        id="email"
                                                        className={error.Email ? "input-error" : null}
                                                        type="email"
                                                        value={email}
                                                        placeholder="Dirección de correo electrónico"
                                                        onChange={e => setEmail(e.target.value)}></input>
                                                </div>
                                            )
                                        }

                                        <div className="form-control">
                                            <label>Contraseña <span>*</span>:</label>
                                            <input
                                                className={error.Clave ? "input-error" : null}
                                                type={hidden ? "text" : "password"}
                                                value={contrasena}
                                                placeholder="Contraseña"
                                                onChange={e => setContrasena(e.target.value)}></input>
                                            {hidden ? (
                                                <div>
                                                    <img className="button-hidden" src={visibility} onClick={e => setHidden(false)} alt="icon minus" width="15px" height="15px"></img>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img className="button-hidden" src={novisibility} onClick={e => setHidden(true)} alt="icon minus" width="15px" height="15px"></img>
                                                </div>
                                            )}
                                            {error.Clave ? (
                                                <span className="error">
                                                    <b>{error.Clave}</b>
                                                </span>
                                            ) : null}
                                        </div>

                                        <div className="form-check">
                                            <input type="checkbox" defaultValue="true" id="terminos_condiciones" name="terminos_condiciones" onClick={e => handleCheckbox('terminos_condiciones')}></input>
                                            <label htmlFor="terminos_condiciones">
                                                <span></span>
                                                Acepto <a href={pdfPoliticaTratamiento} target="_blank">solicitud de autorizacion de datos personales</a> y <a href={pdfTerminos} target="_blank">términos y condiciones</a>
                                            </label>
                                        </div>
                                        {error.TyC ? (
                                            <span className="error">
                                                <b>* Acepte autorización de datos personales y términos y condiciones</b>
                                            </span>
                                        ) : null}

                                        {errorg ? (
                                            <span className="error">
                                                <b>* {errorg}</b>
                                            </span>
                                        ) : null}

                                    </React.Fragment>
                                )
                            }
                            <button type="submit" className="btn-large" >Registrar</button>
                            <button type="button" className="btn-large " onClick={() => handleClose()}>Cancelar</button>
                        </form>
                    </div>

                </div>
            </div>
            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            {moveLogin == 2 && (<Redirect to='/login' />)}
        </div>
    )
}
