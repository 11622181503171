import axios from 'axios';

export const KEY_TOKEN = "token";
export const KEY_DOC = "doc";
export const KEY_TIPO_US = "user";
export const KEY_SUCURSAL = "sucursal";
export const KEY_CATEGORIAS = "categorias";
export const KEY_CARRITO = "carrito";
export const KEY_LISTA_JR = "listajr";
export const KEY_NUM_DOC = "numdoc";
export const KEY_CARTERA = "numdoc_cartera";
export const KEY_UBICACION = "ubicacion";

const Auth = {
    signout() {
        localStorage.removeItem(KEY_TOKEN);
        localStorage.removeItem(KEY_SUCURSAL);
        localStorage.removeItem(KEY_DOC);
        localStorage.removeItem(KEY_CARRITO);
        localStorage.removeItem(KEY_CATEGORIAS);
        localStorage.removeItem(KEY_TIPO_US);
        localStorage.removeItem(KEY_LISTA_JR);
        localStorage.removeItem(KEY_NUM_DOC);
        localStorage.removeItem(KEY_CARTERA);
        localStorage.removeItem(KEY_UBICACION);
    },

    setToken(token) {
        return localStorage.setItem(KEY_TOKEN, token);
    },

    getToken() {
        return localStorage.getItem(KEY_TOKEN);
    },

    setDoc(doc) {
        return localStorage.setItem(KEY_DOC, doc);
    },

    getDoc() {
        return localStorage.getItem(KEY_DOC);
    },

    setNumDoc(numdoc) {
        return localStorage.setItem(KEY_NUM_DOC, numdoc);
    },

    getNumDoc() {
        return localStorage.getItem(KEY_NUM_DOC);
    },

    removeNumDoc() {
        localStorage.removeItem(KEY_NUM_DOC);
    },

    setCartera(numdoc) {
        return localStorage.setItem(KEY_CARTERA, numdoc);
    },

    getCartera() {
        return localStorage.getItem(KEY_CARTERA);
    },

    removeCartera() {
        localStorage.removeItem(KEY_CARTERA);
    },

    setTipoUsuario(tipoUs) {
        return localStorage.setItem(KEY_TIPO_US, tipoUs);
    },

    getTipoUsuario() {
        return localStorage.getItem(KEY_TIPO_US);
    },

    setSucursal(sucursal) {
        return localStorage.setItem(KEY_SUCURSAL, JSON.stringify(sucursal));
    },

    getSucursal() {
        return JSON.parse(localStorage.getItem(KEY_SUCURSAL)) || {};
    },

    setCategorias(categorias) {
        return localStorage.setItem(KEY_CATEGORIAS, JSON.stringify(categorias));
    },

    getCategorias() {
        return JSON.parse(localStorage.getItem(KEY_CATEGORIAS)) || {};
    },
    
    setCarrito(carrito) {
        return localStorage.setItem(KEY_CARRITO, JSON.stringify(carrito));
    },

    getCarrito() {
        return JSON.parse(localStorage.getItem(KEY_CARRITO)) || {};
    },

    setListaJR(lista) {
        return localStorage.setItem(KEY_LISTA_JR, JSON.stringify(lista));
    },

    getListaJR() {
        return JSON.parse(localStorage.getItem(KEY_LISTA_JR)) || {};
    },

    setUbicacion(lista) {
        return localStorage.setItem(KEY_UBICACION, JSON.stringify(lista));
    },

    getUbicacion() {
        return JSON.parse(localStorage.getItem(KEY_UBICACION)) || {};
    },

    removeCarrito() {
        localStorage.removeItem(KEY_CARRITO);
    },

    headerToken() {
        axios.interceptors.request.use(function(config) {
            const token = localStorage.getItem(KEY_TOKEN);
            if ( token !== null ) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function(err) {
            return Promise.reject(err);
        });
    }
      
}

export default Auth
  